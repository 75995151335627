/*eslint require-yield: "off"*/
import { types, flow, getSnapshot, onSnapshot, applySnapshot } from 'mobx-state-tree'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth"
import { useCollection } from "react-firebase-hooks/firestore"
// import { collection, getDoc, getDocs, doc, query, where, updateDoc } from 'firebase/firestore/lite'
import { collection, getDoc, getDocs, doc, query, where, updateDoc, onSnapshot as FirestoreOnSnapshot } from 'firebase/firestore'

import firebase, { firestore } from "../service/firebase"

import AppModel from './app'

import API from '../service/api'

const auth = getAuth();

const User = types
    .model('User', {
        account_type: types.maybeNull(types.number), 
        access_token: types.maybeNull(types.string),
        refresh_token: types.maybeNull(types.string),
        id: types.maybeNull(types.string),
        display_name: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        loading_project: types.maybeNull(types.boolean, true),
        saving_publish: types.maybeNull(types.boolean, false),
        is_paying: types.maybeNull(types.boolean, false),
        credits: types.maybeNull(types.number), 
        purchase_history: types.maybeNull(types.string),
        realtimeListenerSet: types.maybeNull(types.boolean, false),
    })
    .actions(self => ({
        init: flow(function* () {

            // Local from local storage, if data exists.
			if (typeof window !== 'undefined' && window.localStorage.getItem('User')) {
                let existingData = JSON.parse(window.localStorage.getItem('User'))

                existingData.loading_project = true
                existingData.is_paying = false
                existingData.realtimeListenerSet = false

				applySnapshot(User, existingData)
			}
        }),
        setRealtimeListenerSet: flow(function* (bool) {
            self.realtimeListenerSet = bool
        }),
        retrieveStoreData: flow(function* () {
            return new Promise((resolve) => {

                console.log('retrieveStoreData 0')

                const docRef = doc(firestore, "store", self.email);

                getDoc(docRef).then((docSnap) => {

                    if (docSnap.exists()) {

                        let data = docSnap.data()

                        console.log('retrieveStoreData 1', data)

                        if (data) {

                            self.setAccountType(data.account_type)
                            self.setCredits(data.credits)
                            self.setPurchaseHistory(JSON.stringify(data.purchase_history))

                            resolve(true)
                        } else {
                            resolve(false)
                        }
                        
                    } else {

                        console.log("Firebase: cannot find project.");
                        resolve(false)
                    }
                });
            })
        }),
        setPurchaseHistory: flow(function* (history) {
            self.purchase_history = history
        }),
        setCredits: flow(function* (credits) {
            self.credits = credits
        }),
        buyCredits: flow(function* (quantity) {
            console.log('buyCredits', quantity)
            return new Promise((resolve) => {

                self.getValidToken().then((access_token) => {

                    fetch(`${API.base}/store/buyCredits`, {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
                            token: access_token,
                            quantity
						})
					})
                    .then(response => response.json())
                    .then(data => {
                        console.log('buyCredits response', data)

                        if (typeof data.url !== 'undefined') {
                            window.open(data.url, '_blank').focus()
                        }
                    })
                    .catch(error => {
                        console.log('buyCredits error', error)
                    })
                })
            })
            
        }),
        upgrade: flow(function* (plan, paymentMethodType) {
			console.log('upgrade 0', plan, paymentMethodType)

            self.setIsPaying(true)
            
            return new Promise((resolve) => {

                self.getValidToken().then((access_token) => {

                    fetch(`${API.base}/store/upgrade`, {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
                            token: access_token,
                            plan,
                            paymentMethodType
						})
					})
                    .then(response => response.json())
                    .then(data => {
                        console.log('upgrade response', data)

                        if (typeof data.url !== 'undefined') {
                            window.open(data.url, '_blank').focus()
                        }

                        self.setIsPaying(false)
                    })
                    .catch(error => {
                        console.log('upgrade error', error)
                    })
                })
            })
        }),
        setAccountType: flow(function* (bool) {
            self.account_type = bool
        }),
        setIsPaying: flow(function* (bool) {
            self.is_paying = bool
        }),
        getValidToken: flow(function* () {
            return new Promise((resolve) => {
                self.parseToken(self.access_token).then((currentToken) => {
                    if (currentToken.exp <= new Date().getTime() / 1000) {
                        self.refreshAccessToken().then((currentToken) => {
                            resolve(currentToken)
                        })
                    } else {
                        resolve(self.access_token)
                    }
                })
            })
        }),
        parseToken: flow(function* (token) {
            return new Promise((resolve) => {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            
                resolve(JSON.parse(jsonPayload))
            })
        }),
        startBuild: flow(function* (type) {

            return new Promise((resolve) => {

                self.getValidToken().then((access_token) => {
                            
                    console.log('startBuild', type)

                    fetch(`${API.base}/system/build`, {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							token: access_token,
						})
					})
                    .then(response => response.json())
                    .then(data => {
                        console.log('startBuild', data) 
                    })
                })
            })
		}),
		stopBuild: flow(function* (type) {
			console.log('stopBuild', type)
			
            alert('deprecated');
		}),
        setAccessToken: flow(function* (access_token) {
            self.access_token = access_token
        }),
        refreshAccessToken: flow(function* () {
            return new Promise((resolve) => {
                const auth = getAuth()
                const user = auth.currentUser

                auth.currentUser.getIdToken(true).then((access_token) => {
                    self.setAccessToken(access_token)
                    resolve(access_token)
                })
            })
        }),
        getPipelineStatus: flow(function* () {
            return new Promise((resolve) => {

                console.log('TODO: getPipelineStatus')

                // self.getValidToken().then((access_token) => {

                //     fetch(`http://localhost:3000/api/getPipelineStatus`, {
                //         method: 'POST',
                //         body: JSON.stringify({
                //             access_token
                //         })
                //     })
                //     .then(response => response.json())
                //     .then(data => {
                //         if (data.pipeline) {
                //             AppModel.setPipeline(data.pipeline)
                //         }
                //     })
                // })
            })
        }),
        askVersionToUse: flow(function* () {
            self.ask_version = true
        }),
        getProject: flow(function* () {
            self.setIsProjectLoading(true)

            self.retrieveStoreData()

            return new Promise((resolve) => {

                console.log('what am i?', firestore)

                const docRef = doc(firestore, "projects", self.email);

                // Listen for realtime changes.
                if (!self.realtimeListenerSet) {
                    FirestoreOnSnapshot(doc(firestore, "projects", self.email), (documentSnapshot) => {
                        self.setRealtimeListenerSet(true)

                        let data = documentSnapshot.data()

                        data.payload.current_screen = AppModel.current_screen
                        data.payload.creation_status = `${data.creation_status}`

                        applySnapshot(AppModel, data.payload)
                    });
                    FirestoreOnSnapshot(doc(firestore, "store", self.email), (documentSnapshot) => {

                        let data = documentSnapshot.data()

                        self.setPurchaseHistory(JSON.stringify(data.purchase_history))
                        self.setAccountType(data.account_type)
                        self.setCredits(data.credits)
                    });
                }

                getDoc(docRef).then((docSnap) => {

                    if (docSnap.exists()) {

                        let data = docSnap.data()

                        if (data && typeof data.payload !== 'undefined') {
                            // Do not open a previously open screen.
                            data.payload.current_screen = null
                            data.payload.creation_status = `${data.creation_status}`

                            applySnapshot(AppModel, data.payload)

                            // Load home screen
                            AppModel.screens.forEach((screen) => {
                                if (screen.original_id.toLowerCase() === 'home') {
                                    AppModel.setCurrentScreen('HOME')
                                }
                            })
                        }

                        self.getPipelineStatus()
                        
                    } else {

                        console.log("Firebase: cannot find project.");
                    }

                    self.setIsProjectLoading(false)
                    resolve()
                });
            })
        }),
        setAccountType: flow(function* (type) {
            self.account_type = type
        }),
        saveProjectPublish: flow(function* () {
            self.setIsPublishSaving(true)

            return new Promise((resolve) => {

                const docRef = doc(firestore, "projects", self.email);

                let snapshot = { ...getSnapshot(AppModel) }

                // Clear up stuff we don't need to store.
                delete snapshot.asset_store
                delete snapshot.asset_store_item_in_detail_mode
                delete snapshot.media
                delete snapshot.pipeline
                delete snapshot.push_notification
                delete snapshot.creation_status

                console.log('AppModel', snapshot)

                updateDoc(docRef, { payload: snapshot }).then((docSnap) => {
                    self.setIsPublishSaving(false)

                    // Now build the build
                    self.startBuild().then(() => {
                        resolve()
                    })
                });
            })
        }),
		setIsPublishSaving: flow(function* (boolean) {
            self.saving_publish = boolean
        }),
		setIsProjectLoading: flow(function* (boolean) {
            self.loading_project = boolean
        }),
		setUser: flow(function* (access_token, refresh_token, uid, display_name, email) {
            self.access_token = access_token
            self.refresh_token = refresh_token
            self.id = uid
            self.display_name = display_name
            self.email = email
        }),
        loginWithGoogle: flow(function* () {

            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    
                    self.setUser(result.user.accessToken, result.user.refreshToken, result.user.uid, result.user.displayName, result.user.email)

                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);

                    console.log('Firebase error', error.message)
                    // ...
                });
        }),
        logout: flow(function* () {
            if (typeof window !== 'undefined') {
                window.localStorage.removeItem('AppModel')
                window.localStorage.removeItem('User')
			}

            self.id = null
            self.display_name = null
            self.email = null
            self.loading_project = false
        })
    }))
    .views(self => ({
        getUser() {
            return {
                id: `${self.id}`,
                display_name: `${self.display_name}`,
                email: `${self.email}`
            }
        },
        getDisplayName() {
            return self.display_name
        },
        isLoggedIn() {
            return self.id ? true : false
        },
        isProjectLoading() {
            return self.loading_project ? true : false
        },
        isPublishSaving() {
            return self.saving_publish ? true : false
        },
        shouldAskVersionToUse() {
            return self.ask_version ? true : false
        },
        getPurchaseHistory() {
            let list = []

            JSON.parse(self.purchase_history).forEach((purchase) => {
                list.push(<a href={ purchase.invoice } target="_blank" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0', color: 'white', borderBottom: '2px solid #1B2533' }}>
                    <div>
                        <span style={{ fontSize: 12 }}>{ `${new Date(purchase.date)}` }</span>
                        <br/>
                        Total: ${purchase.amount / 100}
                    </div>
                    <strong style={{ color: '#db2777' }}>Download</strong>
                </a>)
            })

            return list
        }
    })).create()

export default User;

onSnapshot(User, snapshot => typeof window !== 'undefined' ? window.localStorage.setItem('User', JSON.stringify(snapshot)) : null);
