import { observer } from 'mobx-react'
import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import UpgradeModal from './upgrade'

import AppModel from '../model/app'
import USER from '../model/user'
import BuyCredits from './buyCredits'

export default observer(function AccountModal(props) {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>My Account</Button>}
    >
      <Modal.Header>
        Hello, { USER.getDisplayName() } 👋 
        <Button style={{ position: 'absolute', top: 12, right: 20 }} color='green' onClick={() => USER.logout().then(() => setOpen(false))}>Log out</Button>
      </Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          <Header>Subscription</Header>
          <p>You are currently on the <strong>{ USER.account_type === 1 ? `Personal Plan` : USER.account_type === 2 ? `Business Plan` : USER.account_type === 3 ? `Business Pro Plan` : `` }</strong> ({ USER.account_type === 1 ? `$80` : USER.account_type === 2 ? `$200` : USER.account_type === 3 ? `$400` : `` } per month).</p>

          <UpgradeModal />

          <div style={{ display: 'flex', marginTop: 30, marginBottom: 30, justifyContent: 'flex-end' }}>
            <Button onClick={() => { }}>View Invoices</Button>
            <Button onClick={() => { }}>Cancel Subscription</Button>
          </div>

          <Header>Credits</Header>
          <p>You have <strong>{ USER.credits } credits</strong>.</p>
          <BuyCredits />
          
          <Header style={{ marginTop: 50 }}>External Services</Header>
          <p>There are a couple of external services that must be configured in order for your app to function correctly.</p>
          <Accordion.Accordion panels={
            [
              {
                key: `Apple Developer`, 
                title: `Apple Developer`, 
                content: <Accordion.Content style={{  flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                      <Label style={{ margin: '0 0 5px' }}>
                          <span style={{ marginRight: 10 }}>Project ID</span>
                          <Input type='text' name="google_firebase_id" defaultValue={ AppModel.get_settings() ? AppModel.get_settings().firebase_id : null } onChange={ (e, data) => { AppModel.updateSettings('firebase_id', `${data.value}`) } }/>
                      </Label>
                      <Label style={{ margin: '0 0 5px' }}>
                          <span style={{ marginRight: 10 }}>Project ID</span>
                          <Input type='text' name="google_firebase_id" defaultValue={ AppModel.get_settings() ? AppModel.get_settings().firebase_id : null } onChange={ (e, data) => { AppModel.updateSettings('firebase_id', `${data.value}`) } }/>
                      </Label>
                </Accordion.Content>
              },
              {
                key: `Google Play Store`, 
                title: `Google Play Store`, 
                content: <Accordion.Content style={{  flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                      <Label style={{ margin: '0 0 5px' }}>
                          <span style={{ marginRight: 10 }}>Project ID</span>
                          <Input type='text' name="google_firebase_id" defaultValue={ AppModel.get_settings() ? AppModel.get_settings().firebase_id : null } onChange={ (e, data) => { AppModel.updateSettings('firebase_id', `${data.value}`) } }/>
                      </Label>
                      <Label style={{ margin: '0 0 5px' }}>
                          <span style={{ marginRight: 10 }}>Project ID</span>
                          <Input type='text' name="google_firebase_id" defaultValue={ AppModel.get_settings() ? AppModel.get_settings().firebase_id : null } onChange={ (e, data) => { AppModel.updateSettings('firebase_id', `${data.value}`) } }/>
                      </Label>
                </Accordion.Content>
              },
            ]
          } style={{ marginBottom: 40 }} />

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='white' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
})