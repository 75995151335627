/*eslint require-yield: "off"*/
import { types, flow, getSnapshot } from 'mobx-state-tree'
import { SketchPicker } from 'react-color'

import Component from './component';
import ComponentAttributes from './componentAttributes'
import UserLevel from './userLevel';

import { Button, Form, Icon, Select, Label, Input, Dropdown, Accordion } from 'semantic-ui-react'

import styles from '../../styles/EditComponentWindow.module.css'
import attributesByComponent from '../../renderer/attributes'
import MediaThumbnail from '../../renderer/media/thumbnail'

import controlStyles from '../../styles/Controls.module.css'
import editComponentWindowStyles from '../../styles/EditComponentWindow.module.css'

import AddComponentModal from '../../modals/addComponent'

import MediaGalleryModal from '../../modals/mediaGallery'

import defaults from '../../options/defaults'

import MODEL from '../app';
import AppModel from '../app';

const Screen = types
    .model('Screen', {
        id: types.identifier,
        original_id: types.string,
        type: types.optional(types.string, 'screen'),
        components: types.optional(types.array(Component), []),
        component_in_edit_mode: types.maybeNull(Component),
        is_menu: types.maybeNull(types.boolean),
        user_levels: types.optional(types.array(UserLevel), []),
     })
    .actions(self => ({
        updateAttribute: flow(function* (key, value) {
            self[key] = value
        }),
        addComponent: flow(function* (component) {
            self.components.push(component)
        }),
        replaceComponents: flow(function* (components) {
            let allComponents = []

            components.forEach((component) => {
                let individualAttributes = { id: component.id }
            
                Object.keys(component.getAttributes()).map((key) => {
                    if (key === 'styles') {
                        individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
                    } else {
                        individualAttributes[key] = component.getAttributes()[key]
                    }
                })
                
                let attributes = ComponentAttributes.create(individualAttributes)

                let children = []

                if (JSON.parse(component.children)) {
                    
                    JSON.parse(component.children).forEach((component) => {
                        children.push(getSnapshot(Component.create(component)))
                    })
                }

                allComponents.push(Component.create({
                    id: `unique-${component.id}-${new Date().getTime()}`,
                    original_id: component.original_id,
                    parent_id: component.parent_id,
                    type: component.type,
                    attributes,
                    children: children.length > 0 ? JSON.stringify(children) : null
                }))
            })

            self.components = allComponents
        }),
        moveComponent: flow(function* (direction, uniqueID) {
            let allComponents = []

            self.components.forEach((component) => {

                let individualAttributes = { id: component.id }
            
                Object.keys(component.getAttributes()).map((key) => {
                    if (key === 'styles') {
                        individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
                    } else {
                        individualAttributes[key] = component.getAttributes()[key]
                    }
                })
                
                let attributes = ComponentAttributes.create(individualAttributes)

                let children = []

                if (JSON.parse(component.children)) {
                    
                    JSON.parse(component.children).forEach((component) => {
                        children.push(getSnapshot(Component.create(component)))
                    })
                }
                
                allComponents.push(Component.create({
                    id: component.id,
                    original_id: component.original_id,
                    parent_id: component.parent_id,
                    type: component.type,
                    attributes,
                    children: children.length > 0 ? JSON.stringify(children) : null
                }))
            })

            // Only possible to move components if the tree has more than one components.
            if (allComponents.length < 2) {
                return
            }

            // Find current location of the component
            let currentPosition = null
            
            allComponents.forEach((component, index) => {
                if (uniqueID === component.original_id) {
                    currentPosition = index
                }
            })

            if (typeof currentPosition !== 'undefined') {
                if (direction === 'up') {
                    allComponents = arrayMove(allComponents, currentPosition, currentPosition - 1)
                } else if (direction === 'down') {
                    allComponents = arrayMove(allComponents, currentPosition, currentPosition + 1)
                }
            }

            self.components = allComponents
        }),
        deleteComponent: flow(function* (uniqueID) {

            let allComponents = []

            self.components.forEach((component) => {

                if (component.original_id !== uniqueID) {
                    let individualAttributes = { id: component.id }
                
                    Object.keys(component.getAttributes()).map((key) => {
                        if (key === 'styles') {
                            individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
                        } else {
                            individualAttributes[key] = component.getAttributes()[key]
                        }
                    })
                    
                    let attributes = ComponentAttributes.create(individualAttributes)

                    let children = []

                    if (JSON.parse(component.children)) {
                        
                        JSON.parse(component.children).forEach((component) => {

                            // Check children as the component to be deleted might be a child.
                            if (component.original_id !== uniqueID) {
                                children.push(getSnapshot(Component.create(component)))
                            }
                        })
                    }
                    
                    allComponents.push(Component.create({
                        id: component.id,
                        original_id: component.original_id,
                        parent_id: component.parent_id,
                        type: component.type,
                        attributes,
                        children: JSON.stringify(children)
                    }))
                }
            })

            self.components = allComponents

            MODEL.replaceCurrentScreenComponents(allComponents)
        }),
        duplicateComponent: flow(function* (uniqueID) {
            let allComponents = []
            let componentToDuplicate = null

            self.components.forEach((component) => {

                let individualAttributes = { id: component.attributes.id }
            
                Object.keys(component.getAttributes()).map((key) => {
                    if (key === 'styles') {
                        individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
                    } else {
                        individualAttributes[key] = component.getAttributes()[key]
                    }
                })
                
                let attributes = ComponentAttributes.create(individualAttributes)

                let children = []

                if (JSON.parse(component.children)) {
                    
                    JSON.parse(component.children).forEach((component) => {
                        children.push(getSnapshot(Component.create(component)))
                    })
                }

                allComponents.push(Component.create({
                    id: component.id,
                    original_id: component.original_id,
                    parent_id: component.parent_id,
                    type: component.type,
                    attributes,
                    children: children.length > 0 ? JSON.stringify(children) : null
                }))

                if (component.id === uniqueID) {
                    componentToDuplicate = Component.create({
                        id: `${component.id}-duplicated-${new Date().getTime()}`,
                        original_id: component.original_id,
                        parent_id: component.parent_id,
                        type: component.type,
                        attributes: ComponentAttributes.create(individualAttributes),
                        children: children.length > 0 ? JSON.stringify(children) : null
                    })
                }
            })

            if (componentToDuplicate) {
                allComponents.push(componentToDuplicate)
            }

            self.components = allComponents

            MODEL.replaceCurrentScreenComponents(allComponents)
        }),
		openEditMode: flow(function* (component) {
            
            let individualAttributes = { id: component.id }

            console.log('openEditMode', component)

            if (component && component.getAttributes()) {
                
                Object.keys(component.getAttributes()).map((key) => {
                    if (key === 'styles') {
                        individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
                    } else {
                        individualAttributes[key] = component.getAttributes()[key]
                    }
                })
                
                let attributes = ComponentAttributes.create(individualAttributes)
                
                self.component_in_edit_mode = Component.create({
                    id: `${component.id}-edit-mode-${new Date().getTime()}`,
                    original_id: component.original_id,
                    parent_id: component.parent_id,
                    original_id: `${component.original_id}`,
                    type: component.type,
                    attributes
                })
            }
		}),
        exitEditMode: flow(function* () {
            self.component_in_edit_mode = null
        }),
        handleClick: flow(function* (ev, type, destination, component_id, component_parent_id) {
            if (ev) ev.preventDefault()

            if (MODEL.in_preview_mode) {
                if (type === 'screen') {
                    MODEL.setCurrentScreen(destination)
                }
            } else {

                // Find component (it might be a child...)
                let component = null
                let isChildComponent = false
                
                self.components.forEach((_component, index) => {
                    if (_component.original_id === component_parent_id) {
                        
                        isChildComponent = true
                        // Find child.

                        if (JSON.parse(_component.children)) {
                            JSON.parse(_component.children).forEach((child, index) => {
                                if (child.original_id === component_id) {
                                    component = Component.create(child)
                                }
                            })
                        }

                    } else if (_component.original_id === component_id) {

                        component = _component

                    } else {

                        // Check one more level of children (children's children)
                        if (JSON.parse(_component.children)) {
                            JSON.parse(_component.children).forEach((child, index) => {
                                if (JSON.parse(child.children)) {
                                    JSON.parse(child.children).forEach((child, index) => {
                                        if (child.original_id === component_id) {
                                            component = Component.create(child)
                                        }
                                    })
                                }
                            })
                        }
                    }
                })

                if (component) {

                    self.openEditMode(component)
                }
            }
        }),
        updateComponentStyleAttribute: flow(function* (component, attribute, value) {
            let attributes = JSON.parse(component.attributes.styles)

            attributes[attribute] = value

            component.attributes.updateStyles(attributes)

            let originalComponentIndex = undefined
            let originalChildComponentIndex = null
            let isChildComponent = false
            let isGrandChildComponent = false

            self.components.forEach((_component, index) => {
                if (_component.original_id === component.parent_id) {
                    originalComponentIndex = index
                    isChildComponent = true
                } else if (_component.original_id === component.original_id) {
                    originalComponentIndex = index
                } else {
                    if (JSON.parse(_component.children)) {
                        JSON.parse(_component.children).forEach((child, childIndex) => {
                            if (child.original_id === component.parent_id) {
                                originalComponentIndex = index
                                originalChildComponentIndex = childIndex
                                isGrandChildComponent = true
                            }
                        })
                    }
                }
            })

            if (typeof originalComponentIndex !== 'undefined' && !isNaN(originalComponentIndex)) {

                if (isChildComponent && JSON.parse(self.components[originalComponentIndex].children)) {

                    JSON.parse(self.components[originalComponentIndex].children).forEach((child, childIndex) => {
                        if (component.original_id === child.original_id) {
                            self.components[originalComponentIndex].updateChildStyles(attributes, childIndex)
                        }
                    })

                // GRANDCHILDREN
                } else if (isGrandChildComponent && JSON.parse(self.components[originalComponentIndex].children)) {

                    self.components[originalComponentIndex].updateGrandChildStyles(attributes, originalChildComponentIndex, originalComponentIndex)

                    self.openEditMode(self.components[originalComponentIndex].children[originalChildComponentIndex])

                } else {

                    self.components[originalComponentIndex].attributes.updateStyles(attributes)

                    self.openEditMode(self.components[originalComponentIndex])
                }
            }


            MODEL.replaceCurrentScreenComponents(self.components, true)
        }),
        updateComponentAttribute: flow(function* (component, attribute, value) {
            component.attributes[attribute] = value

            component.attributes.id = `unique-${new Date().getTime()}`

            let originalComponentIndex = null
            let isChildComponent = false
            let originalChildComponentIndex = null
            let isGrandChildComponent = false

            self.components.forEach((_component, index) => {
                if (_component.original_id === component.parent_id) {
                    originalComponentIndex = index
                    isChildComponent = true
                } else if (_component.original_id === component.original_id) {
                    originalComponentIndex = index
                } else {
                    if (JSON.parse(_component.children)) {
                        JSON.parse(_component.children).forEach((child, childIndex) => {
                            if (child.original_id === component.parent_id) {
                                originalComponentIndex = index
                                originalChildComponentIndex = childIndex
                                isGrandChildComponent = true
                            }
                        })
                    }
                }
            })

            if (typeof originalComponentIndex !== 'undefined' && !isNaN(originalComponentIndex)) {

                // CHILDREN
                if (isChildComponent && JSON.parse(self.components[originalComponentIndex].children)) {

                    JSON.parse(self.components[originalComponentIndex].children).forEach((child, index) => {
                        if (component.original_id === child.original_id) {
                            self.components[originalComponentIndex].updateChildAttributes(component.attributes, index)
                        }
                    })

                // GRANDCHILDREN
                } else if (isGrandChildComponent && JSON.parse(self.components[originalComponentIndex].children)) {

                    self.components[originalComponentIndex].updateGrandChildAttributes(component.attributes, originalChildComponentIndex, originalComponentIndex)

                } else {

                    if (self.components[originalComponentIndex]) {
                        self.components[originalComponentIndex].updateAttributes(component.attributes)
                    }
                }
            }

            self.openEditMode(component)

            MODEL.replaceCurrentScreenComponents(self.components, true)
        }),
        save: flow(function* () {
            // Update origin
            MODEL.replaceCurrentScreenComponents(self.components)
        })
    }))
    .views(self => ({
        getComponents() {
            return self.components
        },
        getComponentByOriginalID(original_id) {

            let foundComponent = null

            self.components.forEach((component) => {

                let individualAttributes = { id: component.attributes.id }
            
                Object.keys(component.getAttributes()).map((key) => {
                    if (key === 'styles') {
                        individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
                    } else {
                        individualAttributes[key] = component.getAttributes()[key]
                    }
                })
                
                let children = []

                if (JSON.parse(component.children)) {
                    
                    JSON.parse(component.children).forEach((component) => {
                        children.push(getSnapshot(Component.create(component)))
                    })
                }

                if (component.original_id === original_id) {
                    foundComponent = Component.create({
                        id: `${component.id}-duplicated-${new Date().getTime()}`,
                        original_id: component.original_id,
                        parent_id: component.parent_id,
                        type: component.type,
                        attributes: ComponentAttributes.create(individualAttributes),
                        children: children.length > 0 ? JSON.stringify(children) : null
                    })
                }
            })

            return foundComponent
        },
        renderEditMenuPanels() {
            let panels = []

            panels.push(
                {
                    key: `Type of menu`,
                    title: `Type of menu`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                { key: 'drawer', value: 'drawer', text: 'Drawer' },
                                { key: 'modal', value: 'modal', text: 'Modal' },
                            ]} value={ AppModel.menu.type } onChange={ (e, data) => { AppModel.menu.updateAttribute('type', data.value) } } />
                        </div>
                    </Accordion.Content>
                }
            )

            panels.push(
                {
                    key: `Menu Position`,
                    title: `Menu Position`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                { key: 'left', value: 'left', text: 'Left' },
                                { key: 'right', value: 'right', text: 'Right' },
                            ]} value={ AppModel.menu.type } onChange={ (e, data) => { AppModel.menu.updateAttribute('drawer_position', data.value) } } />
                        </div>
                    </Accordion.Content>
                }
            )

            panels.push(
                {
                    key: `Menu content should bounce?`,
                    title: `Menu content should bounce?`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                { key: 'yes', value: 'yes', text: 'Yes' },
                                { key: 'no', value: 'no', text: 'No' },
                            ]} value={ AppModel.menu.type } onChange={ (e, data) => { AppModel.menu.updateAttribute('should_bounce', data.value) } } />
                        </div>
                    </Accordion.Content>
                }
            )

            panels.push(
                {
                    key: `Menu Icon (to open)`,
                    title: `Menu Icon (to open)`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            { AppModel.menu.computed_tab_icon() ? <MediaThumbnail id={ AppModel.menu.computed_tab_icon() } width={40} height={40} /> : null }
                            <MediaGalleryModal componentInEditMode={true} attribute={`menu_icon`} />
                        </div>
                    </Accordion.Content>
                }
            )

            panels.push(
                {
                    key: `Menu Icon (to close)`,
                    title: `Menu Icon (to close)`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            { AppModel.menu.computed_tab_close_icon() ? <MediaThumbnail id={ AppModel.menu.computed_tab_close_icon() } width={40} height={40} /> : null }
                            <MediaGalleryModal componentInEditMode={true} attribute={`menu_icon_close`} />
                        </div>
                    </Accordion.Content>
                }
            )

            panels.push(
                {
                    key: `Menu icon position`,
                    title: `Menu icon position`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                { key: 'top_left', value: 'top_left', text: 'Top-Left' },
                                { key: 'top_right', value: 'top_right', text: 'Top-Right' },
                                { key: 'bottom_left', value: 'bottom_left', text: 'Bottom-Left' },
                                { key: 'bottom_right', value: 'bottom_right', text: 'Bottom-Right' },
                            ]} value={ AppModel.menu.icon_position } onChange={ (e, data) => { AppModel.menu.updateAttribute('icon_position', data.value); window.dispatchEvent(new Event('resize')); } } />

                            { console.log('JSON.parse(AppModel.menu.tab)', JSON.parse(AppModel.menu.tab)) }

                            <div className={styles.editComponentWindowFieldContainer}>
                                <ul className={styles.editComponentWindowFieldList}>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-top.svg'} /></span>
                                            <Input type='number' name="margin_top" defaultValue={ parseFloat(JSON.parse(AppModel.menu.tab).position.marginTop) } onChange={ (e, data) => { AppModel.menu.updateMenuTabPosition('marginTop', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-right.svg'} /></span>
                                            <Input type='number' name="margin_right" defaultValue={ parseFloat(JSON.parse(AppModel.menu.tab).position.marginRight) } onChange={ (e, data) => { AppModel.menu.updateMenuTabPosition('marginRight', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-bottom.svg'} /></span>
                                            <Input type='number' name="margin_bottom" defaultValue={ parseFloat(JSON.parse(AppModel.menu.tab).position.marginBottom) } onChange={ (e, data) => { AppModel.menu.updateMenuTabPosition('marginBottom', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-left.svg'} /></span>
                                            <Input type='number' name="margin_left" defaultValue={ parseFloat(JSON.parse(AppModel.menu.tab).position.marginLeft) } onChange={ (e, data) => { AppModel.menu.updateMenuTabPosition('marginLeft', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                        </Label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Accordion.Content>
                }
            )

            let stylePanels = self.prepareStylePanels('open')

            panels.push(
                {
                    key: `Menu styles (when menu is not shown)`,
                    title: `Menu styles (when menu is not shown)`,
                    content: <Accordion.Content>
                        <Accordion.Accordion panels={ stylePanels } style={{ marginBottom: 40, width: '100%' }} />
                    </Accordion.Content>
                }
            )

            stylePanels = self.prepareStylePanels('close')

            panels.push(
                {
                    key: `Menu styles (when menu is shown)`,
                    title: `Menu styles (when menu is shown)`,
                    content: <Accordion.Content>
                        <Accordion.Accordion panels={ stylePanels } style={{ marginBottom: 40, width: '100%' }} />
                    </Accordion.Content>
                }
            )

            return panels
        },
        prepareStylePanels(type = 'open') {

            let stylePanels = []

            stylePanels.push(
                {
                    key: `style-panel-0`, 
                    title: `Margin`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <ul className={styles.editComponentWindowFieldList}>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-top.svg'} /></span>
                                        <Input type='number' name="margin_top" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).marginTop) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).marginTop) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('marginTop', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('marginTop', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-right.svg'} /></span>
                                        <Input type='number' name="margin_right" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).marginRight) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).marginRight) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('marginRight', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('marginRight', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-bottom.svg'} /></span>
                                        <Input type='number' name="margin_bottom" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).marginBottom) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).marginBottom) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('marginBottom', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('marginBottom', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-left.svg'} /></span>
                                        <Input type='number' name="margin_left" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).marginLeft) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).marginLeft) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('marginLeft', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('marginLeft', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                            </ul>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-1`, 
                    title: `Padding`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <ul className={styles.editComponentWindowFieldList}>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-top.svg'} /></span>
                                        <Input type='number' name="padding_top" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).paddingTop) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).paddingTop) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('paddingTop', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('paddingTop', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-right.svg'} /></span>
                                        <Input type='number' name="padding_right" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).paddingRight) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).paddingRight) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('paddingRight', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('paddingRight', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-bottom.svg'} /></span>
                                        <Input type='number' name="padding_bottom" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).paddingBottom) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).paddingBottom) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('paddingBottom', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('paddingBottom', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                                <li className={styles.editComponentWindowFieldListItem}>
                                    <Label className={[styles.editComponentWindowFieldListItemLabel ]}>
                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-left.svg'} /></span>
                                        <Input type='number' name="padding_left" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).paddingLeft) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).paddingLeft) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('paddingLeft', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('paddingLeft', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                    </Label>
                                </li>
                            </ul>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-2`, 
                    title: `Width`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                <Input type='number' name="width" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).width) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).width) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('width', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('width', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-3`, 
                    title: `Height`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                <Input type='number' name="height" defaultValue={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).height) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).height) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('height', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('height', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-5`, 
                    title: `Text`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel]}>
                                <Input type='text' name="text" value={ type === 'open' ? JSON.parse(AppModel.menu.tab).text : JSON.parse(AppModel.menu.tab).text } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabAttribute('text', `${data.value}`) : AppModel.menu.updateMenuTabAttribute('text_close', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputText} />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-6`, 
                    title: `Color`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <SketchPicker key={`text_color`} color={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).color) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).color) } onChange={(color, event) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('color', `${color.hex}`) : AppModel.menu.updateMenuTabCloseStyles('color', `${color.hex}`) }} />
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-7`, 
                    title: `Text Size`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                <Input type='number' name="fontSize" value={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).fontSize) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).fontSize) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('fontSize', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('fontSize', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-8`, 
                    title: `Text Font`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                <Select placeholder='Select a text font' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={AppModel.get_font_families()} value={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).fontFamily) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).fontFamily) } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('fontFamily', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('fontFamily', `${data.value}`) } } />                                            
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-9`, 
                    title: `Background Color`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <SketchPicker key={`background_color`} color={ type === 'open' ? JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundColor : JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundColor } onChange={(color, event) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('backgroundColor', `${color.hex}`) : AppModel.menu.updateMenuTabCloseStyles('backgroundColor', `${color.hex}`) }} />
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-10`, 
                    title: `Background Image`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            { 
                                type === 'open' ? JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundImage : JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundImage ? 
                                    <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <MediaThumbnail id={ type === 'open' ? parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundImage) : parseFloat(JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundImage) } width={80} height={80} />
                                        { type === 'open' ? JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundImage : JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundImage ? <Button style={{ marginLeft: 5 }} color='red' size="tiny" onClick={() => { type === 'open' ? AppModel.menu.updateMenuTabStyles('backgroundImage', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('backgroundImage', `${data.value}`) } }>x</Button> : null }
                                    </div>
                                :
                                    null
                            }

                            <MediaGalleryModal componentInEditMode={true} attribute={`backgroundImage`} />
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-11`, 
                    title: `Background Repeat`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                <Select placeholder='Select a repeat type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: 'no-repeat', value: 'no-repeat', text: 'No Repeat' },
                                    { key: 'repeat', value: 'repeat', text: 'Repeat' },
                                    { key: 'repeat-x', value: 'repeat-x', text: 'Repeat on X Axis' },
                                    { key: 'repeat-y', value: 'repeat-y', text: 'Repeat on Y Axis' },
                                    { key: 'space', value: 'space', text: 'Space' },
                                ]} value={ type === 'open' ? JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundRepeat : JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundRepeat } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('backgroundRepeat', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('backgroundRepeat', `${data.value}`) } } />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-12`, 
                    title: `Background Size`, 
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                <Select placeholder='Select a repeat type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: 'auto', value: 'auto', text: 'Auto' },
                                    { key: 'cover', value: 'cover', text: 'Cover' },
                                    { key: 'contain', value: 'contain', text: 'Contain' },
                                ]} value={ type === 'open' ? JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundSize : JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundSize } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('backgroundSize', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('backgroundSize', `${data.value}`) } } />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )
            stylePanels.push(
                {
                    key: `style-panel-13`, 
                    title: `Background Position`,
                    content: <Accordion.Content>
                        <div className={styles.editComponentWindowFieldContainer}>
                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                <Select placeholder='Select a repeat type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: '0% 0%', value: '0% 0%', text: 'Auto' },
                                    { key: '50% 50%', value: '50% 50%', text: 'Middle' },
                                ]} value={ type === 'open' ? JSON.parse(JSON.parse(AppModel.menu.tab).styles).backgroundPosition : JSON.parse(JSON.parse(AppModel.menu.tab).close_styles).backgroundPosition } onChange={ (e, data) => { type === 'open' ? AppModel.menu.updateMenuTabStyles('backgroundPosition', `${data.value}`) : AppModel.menu.updateMenuTabCloseStyles('backgroundPosition', `${data.value}`) } } />
                            </Label>
                        </div>
                    </Accordion.Content>
                }
            )

            return stylePanels
        },
        renderEditComponentWindowPanels() {
            let panels = []

            if (self.component_in_edit_mode.type !== 'image' && self.component_in_edit_mode.type !== 'video') {

                panels.push(
                    {
                        key: `layout-direction`, 
                        title: `Layout Direction`, 
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <ul className={styles.editComponentWindowFieldList}>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'direction', 'row') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().direction === 'row' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/flex-row.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="direction_row" value='direction' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'direction', 'column') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().direction === 'column' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/flex-column.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="direction_column" value='direction' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                </ul>
                            </div>
                        </Accordion.Content>
                    },
                    {
                        key: `layout-positioning`, 
                        title: `Layout Positioning`, 
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <ul className={styles.editComponentWindowFieldList}>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'middle') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'middle' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/middle.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_middle" value='middle' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'middle-left') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'middle-left' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/middle-left.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_middle-left" value='middle-left' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'middle-right') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'middle-right' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/middle-right.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_middle-right" value='middle-right' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'top-middle') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'top-middle' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/top-middle.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_top-middle" value='top-middle' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'bottom-middle') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'bottom-middle' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/bottom-middle.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_bottom-middle" value='bottom-middle' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'top-left') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'top-left' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/top-left.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_top-left" value='top-left' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'top-right') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'top-right' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/top-right.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_top-right" value='top-right' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'bottom-left') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'bottom-left' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/bottom-left.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_bottom-left" value='bottom-left' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                    <li className={styles.editComponentWindowFieldListItem}>
                                        <Label onClick={(ev, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'layout', 'bottom-right') }} className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'bottom-right' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                            <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/bottom-right.svg'} /></span>
                                            <Input style={{ display: 'none' }} type='radio' name="layout_bottom-right" value='bottom-right' onChange={ (e, data) => { alert(data.value) } } />
                                        </Label>
                                    </li>
                                </ul>
                            </div>
                        </Accordion.Content>
                    }
                )
            }

            if (self.component_in_edit_mode.type === 'video') {

                console.log('self.component_in_edit_mode.getAttributes()', self.component_in_edit_mode.getAttributes())

                panels.push(
                    {
                        key: `Video`,
                        title: `Video`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                { self.component_in_edit_mode.getAttributes().source ? <MediaThumbnail source={ self.component_in_edit_mode.getAttributes().source } width={40} height={40} /> : null }
                                <MediaGalleryModal componentInEditMode={true} attribute={`source`} />
                            </div>
                        </Accordion.Content>
                    }
                )

                panels.push(
                    {
                        key: `Poster`,
                        title: `Poster`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                { self.component_in_edit_mode.getAttributes().poster ? <MediaThumbnail id={ self.component_in_edit_mode.getAttributes().poster } width={40} height={40} /> : null }
                                <MediaGalleryModal componentInEditMode={true} attribute={`poster`} />
                            </div>
                        </Accordion.Content>
                    }
                )

                panels.push(
                    {
                        key: `Autoplay`,
                        title: `Autoplay`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: true, value: true, text: 'Yes' },
                                    { key: false, value: false, text: 'No' },
                                ]} value={ self.component_in_edit_mode.getAttributes().autoplay } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentAttribute(self.component_in_edit_mode, 'autoplay', data.value) } } />
                            </div>
                        </Accordion.Content>
                    }
                )

                panels.push(
                    {
                        key: `Muted`,
                        title: `Muted`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: true, value: true, text: 'Yes' },
                                    { key: false, value: false, text: 'No' },
                                ]} value={ self.component_in_edit_mode.getAttributes().muted } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentAttribute(self.component_in_edit_mode, 'muted', data.value) } } />
                            </div>
                        </Accordion.Content>
                    }
                )

                panels.push(
                    {
                        key: `Show Controls`,
                        title: `Show Controls`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: true, value: true, text: 'Yes' },
                                    { key: false, value: false, text: 'No' },
                                ]} value={ self.component_in_edit_mode.getAttributes().controls } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentAttribute(self.component_in_edit_mode, 'controls', data.value) } } />
                            </div>
                        </Accordion.Content>
                    }
                )

                panels.push(
                    {
                        key: `Loop`,
                        title: `Loop`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Select placeholder='Select an option' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                    { key: true, value: true, text: 'Yes' },
                                    { key: false, value: false, text: 'No' },
                                ]} value={ self.component_in_edit_mode.getAttributes().loop } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentAttribute(self.component_in_edit_mode, 'loop', data.value) } } />
                            </div>
                        </Accordion.Content>
                    }
                )
            }

            if (self.component_in_edit_mode.type === 'text' || self.component_in_edit_mode.type === 'button') {

                panels.push(
                    {
                        key: `Text`,
                        title: `Text`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Label className={[styles.editComponentWindowFieldListItemLabel]}>
                                    <Input type='text' name="text" value={ self.component_in_edit_mode.getAttributes().text } onChange={ (e, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'text', data.value) } } className={styles.editComponentWindowFieldListItemLabelInputText} />
                                </Label>
                            </div>
                        </Accordion.Content>
                    }
                )
            }

            if (self.component_in_edit_mode.type === 'image') {

                panels.push(
                    {
                        key: `Image`,
                        title: `Image`,
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                { self.component_in_edit_mode.getAttributes().source ? <MediaThumbnail source={ self.component_in_edit_mode.getAttributes().source } width={40} height={40} /> : null }
                                <MediaGalleryModal componentInEditMode={true} attribute={`source`} />
                            </div>
                        </Accordion.Content>
                    }
                )
            }

            if (self.component_in_edit_mode.type === 'login_google') {

                panels.push(
                    {
                        key: `Theme`,
                        title: `Theme`,
                        content: <Accordion.Content>
                            <Select placeholder='Select a theme' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                { key: 'white', value: 'white', text: 'White' },
                                { key: 'blue', value: 'blue', text: 'Blue' },
                                { key: 'red', value: 'red', text: 'Red' },
                            ]} value={ self.component_in_edit_mode.getAttributes().theme } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentAttribute(self.component_in_edit_mode, 'theme', data.value) } } />
                        </Accordion.Content>
                    }
                )
            }

            // Simple panels based on attributes.
            if (defaults[self.component_in_edit_mode.type] && defaults[self.component_in_edit_mode.type].attributes.styles) {

                Object.keys(JSON.parse(defaults[self.component_in_edit_mode.type].attributes.styles)).forEach((attribute) => {
                    switch (attribute) {
                        case 'marginTop': // handles also margin-left, margin-right and margin-bottom
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Margin`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <ul className={styles.editComponentWindowFieldList}>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'marginTop' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-top.svg'} /></span>
                                                        <Input type='number' name="margin_top" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.marginTop) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'marginTop', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'marginRight' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-right.svg'} /></span>
                                                        <Input type='number' name="margin_right" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.marginRight) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'marginRight', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'marginBottom' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-bottom.svg'} /></span>
                                                        <Input type='number' name="margin_bottom" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.marginBottom) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'marginBottom', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'marginLeft' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/margin-left.svg'} /></span>
                                                        <Input type='number' name="margin_left" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.marginLeft) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'marginLeft', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                            </ul>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'paddingTop': // handles also padding-left, padding-right and padding-bottom
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Padding`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <ul className={styles.editComponentWindowFieldList}>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'paddingTop' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-top.svg'} /></span>
                                                        <Input type='number' name="padding_top" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.paddingTop) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'paddingTop', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'paddingRight' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-right.svg'} /></span>
                                                        <Input type='number' name="padding_right" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.paddingRight) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'paddingRight', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'paddingBottom' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-bottom.svg'} /></span>
                                                        <Input type='number' name="padding_bottom" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.paddingBottom) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'paddingBottom', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                                <li className={styles.editComponentWindowFieldListItem}>
                                                    <Label className={[styles.editComponentWindowFieldListItemLabel, self.component_in_edit_mode.getAttributes().layout === 'paddingLeft' ? styles.editComponentWindowFieldListItemLabelChecked : `` ]}>
                                                        <span className={styles.editComponentWindowFieldListItemLabelIcon}><img src={'/assets/icons/padding-left.svg'} /></span>
                                                        <Input type='number' name="padding_left" defaultValue={ parseFloat(self.component_in_edit_mode.getAttributes().styles.paddingLeft) } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'paddingLeft', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputNumber} />
                                                    </Label>
                                                </li>
                                            </ul>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'width':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Width`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                                <Input type='number' name="width" value={ self.component_in_edit_mode.getAttributes().styles.width ? parseFloat(self.component_in_edit_mode.getAttributes().styles.width) : 100 } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'width', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'height':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Height`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                                <Input type='number' name="height" value={ self.component_in_edit_mode.getAttributes().styles.height ? parseFloat(self.component_in_edit_mode.getAttributes().styles.height) : 100 } onChange={ (e, data) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'height', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'source':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Image Source`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            { self.component_in_edit_mode.getAttributes().source ? <MediaThumbnail id={ self.component_in_edit_mode.getAttributes().source } width={40} height={40} /> : null }
                                            <MediaGalleryModal componentInEditMode={true} attribute={`source`} />
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'text':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Text`, 
                                    content: <Accordion.Content>
                                       <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel]}>
                                                <Input type='text' name="text" value={ self.component_in_edit_mode.getAttributes().text } onChange={ (e, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'text', data.value) } } className={styles.editComponentWindowFieldListItemLabelInputText} />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'color':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Color`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <SketchPicker key={`text_color`} color={ self.component_in_edit_mode.getAttributes().styles.color } onChange={(color, event) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'color', color.hex) }} />
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'fontSize':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Text Size`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                                <Input type='number' name="fontSize" value={ self.component_in_edit_mode.getAttributes().styles.fontSize ? parseFloat(self.component_in_edit_mode.getAttributes().styles.fontSize) : 100 } onChange={ (e, data) => { console.log('component_font_size_update', self.component_in_edit_mode, `${data.value}`); self.updateComponentStyleAttribute(self.component_in_edit_mode, 'fontSize', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'fontFamily':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Text Font`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                                <Select placeholder='Select a text font' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={AppModel.get_font_families()} value={ self.component_in_edit_mode.getAttributes().styles.fontFamily } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentStyleAttribute(self.component_in_edit_mode, 'fontFamily', data.value) } } />                                            
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'lineHeight':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Line Height`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelPercentage]}>
                                                <Input type='number' name="lineHeight" value={ self.component_in_edit_mode.getAttributes().styles.lineHeight ? parseFloat(self.component_in_edit_mode.getAttributes().styles.lineHeight) : 100 } onChange={ (e, data) => { console.log('component_font_size_update', self.component_in_edit_mode, `${data.value}`); self.updateComponentStyleAttribute(self.component_in_edit_mode, 'lineHeight', `${data.value}`) } } className={styles.editComponentWindowFieldListItemLabelInputPercentage} />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'backgroundColor':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Background Color`,
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <SketchPicker key={`background_color`} color={ self.component_in_edit_mode.getAttributes().styles.backgroundColor } onChange={(color, event) => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'backgroundColor', color.hex) }} />
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'backgroundImage':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Background Image`,
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            { 
                                                self.component_in_edit_mode.getAttributes().styles.backgroundImage ? 
                                                    <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <MediaThumbnail id={ self.component_in_edit_mode.getAttributes().styles.backgroundImage } width={80} height={80} />
                                                        { self.component_in_edit_mode.getAttributes().styles.backgroundImage ? <Button style={{ marginLeft: 5 }} color='red' size="tiny" onClick={() => { self.updateComponentStyleAttribute(self.component_in_edit_mode, 'backgroundImage', null) } }>x</Button> : null }
                                                    </div>
                                                :
                                                    null
                                            }

                                            <MediaGalleryModal componentInEditMode={true} attribute={`backgroundImage`} />
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'backgroundRepeat':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Background Repeat`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                                <Select placeholder='Select a repeat type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                                    { key: 'no-repeat', value: 'no-repeat', text: 'No Repeat' },
                                                    { key: 'repeat', value: 'repeat', text: 'Repeat' },
                                                    { key: 'repeat-x', value: 'repeat-x', text: 'Repeat on X Axis' },
                                                    { key: 'repeat-y', value: 'repeat-y', text: 'Repeat on Y Axis' },
                                                    { key: 'space', value: 'space', text: 'Space' },
                                                ]} value={ self.component_in_edit_mode.getAttributes().styles.backgroundRepeat } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentStyleAttribute(self.component_in_edit_mode, 'backgroundRepeat', data.value) } } />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'backgroundSize':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Background Size`, 
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                                <Select placeholder='Select a repeat type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                                    { key: 'auto', value: 'auto', text: 'Auto' },
                                                    { key: 'cover', value: 'cover', text: 'Cover' },
                                                    { key: 'contain', value: 'contain', text: 'Contain' },
                                                ]} value={ self.component_in_edit_mode.getAttributes().styles.backgroundSize } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentStyleAttribute(self.component_in_edit_mode, 'backgroundSize', data.value) } } />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;
                        case 'backgroundPosition':
                            panels.push(
                                {
                                    key: `${attribute}`, 
                                    title: `Background Position`,
                                    content: <Accordion.Content>
                                        <div className={styles.editComponentWindowFieldContainer}>
                                            <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                                <Select placeholder='Select a repeat type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                                    { key: '0% 0%', value: '0% 0%', text: 'Auto' },
                                                    { key: '50% 50%', value: '50% 50%', text: 'Middle' },
                                                ]} value={ self.component_in_edit_mode.getAttributes().styles.backgroundPosition } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentStyleAttribute(self.component_in_edit_mode, 'backgroundPosition', data.value) } } />
                                            </Label>
                                        </div>
                                    </Accordion.Content>
                                }
                            )
                        break;

                        default: 
                        break;
                    }
                })
            }

            // Link properties
            if (self.component_in_edit_mode.type !== 'video') {
                panels.push(
                    {
                        key: `link-type`, 
                        title: `Link Type`, 
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelSelect]}>
                                    <Select placeholder='Select a screen' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={[
                                        { key: 'screen', value: 'screen', text: 'Screen' },
                                        { key: 'url', value: 'url', text: 'External URL' }
                                    ]} value={ self.component_in_edit_mode.getAttributes().destination_type } onChange={ (e, data) => { console.log('updating', data.value); self.updateComponentAttribute(self.component_in_edit_mode, 'destination_type', data.value) } } />
                                </Label>
                            </div>
                        </Accordion.Content>
                    },
                    {
                        key: `link-destination`, 
                        title: `Link Destination`, 
                        content: <Accordion.Content>
                            <div className={styles.editComponentWindowFieldContainer}>
                                <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelText]}>
                                    {
                                        self.component_in_edit_mode.getAttributes().destination_type === 'url' ?
                                            <Input type='text' name="destination" value={ self.component_in_edit_mode.getAttributes().destination } onChange={ (e, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'destination', data.value) } } className={styles.editComponentWindowFieldListItemLabelInputText} />
                                        :
                                            <Select placeholder='Select a screen' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={
                                                MODEL.screens.map((screen) => {
                                                    return { key: screen.original_id, value: screen.original_id, text: screen.original_id }
                                                })
                                            } value={ self.component_in_edit_mode.getAttributes().destination } onChange={ (e, data) => { self.updateComponentAttribute(self.component_in_edit_mode, 'destination', data.value) } } />
                                    }
                                </Label>
                            </div>
                        </Accordion.Content>
                    }
                )
            }

            return panels
        },
        renderEditComponentWindow() {            
            return self.component_in_edit_mode ? <div className={`${styles.editComponentWindow} ${ self.component_in_edit_mode ? styles.editComponentWindowActive : null }`} onClick={(ev) => self.save().then(() => { self.exitEditMode()})}>
                <div className={`${styles.editComponentWindowInner}`} onClick={(ev) => ev.stopPropagation()}>
                    <Button className={ styles.editComponentWindowClose } onClick={() => self.save().then(() => { self.exitEditMode()}) }>
                        <Icon name='arrow right' className={styles.editComponentWindowCloseIcon} />
                    </Button>

                    {/* Title */}
                    <h5 className={`${styles.editComponentWindowComponentName} bg-gradient-to-r from-rose-700 to-pink-600`}>
                        { self.component_in_edit_mode.type === 'video' ? <Icon name={ 'play' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'accordion' ? <Icon name={ 'list' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'button' ? <Icon name={ 'linkify' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'image' ? <Icon name={ 'image' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'login_apple' ? <Icon name={ 'apple' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'login_google' ? <Icon name={ 'google' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'login_facebook' ? <Icon name={ 'facebook' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'slider' ? <Icon name={ 'grab' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'text' ? <Icon name={ 'font' } className={controlStyles.controller_button_icon} /> : null }
                        { self.component_in_edit_mode.type === 'view' ? <Icon name={ 'box' } className={controlStyles.controller_button_icon} /> : null }

                        { self.component_in_edit_mode.type === 'video' ? 'Video Player' : null }
                        { self.component_in_edit_mode.type === 'accordion' ? 'Accordion' : null }
                        { self.component_in_edit_mode.type === 'button' ? 'Button' : null }
                        { self.component_in_edit_mode.type === 'image' ? 'Image' : null }
                        { self.component_in_edit_mode.type === 'login_apple' ? 'Apple Login' : null }
                        { self.component_in_edit_mode.type === 'login_google' ? 'Google Login' : null }
                        { self.component_in_edit_mode.type === 'login_facebook' ? 'Facebook Login' : null }
                        { self.component_in_edit_mode.type === 'slider' ? 'Slider' : null }
                        { self.component_in_edit_mode.type === 'text' ? 'Text' : null }
                        { self.component_in_edit_mode.type === 'view' ? 'Container' : null }
                    </h5>

                    <Accordion.Accordion panels={ self.renderEditComponentWindowPanels() } style={{ marginBottom: 40 }} />

                    {
                        self.component_in_edit_mode.parent_id ?
                            <Button onClick={() => { self.openEditMode(self.getComponentByOriginalID(self.component_in_edit_mode.parent_id)) } }>
                                Edit Parent 
                            </Button>
                        : null
                    }

                    {
                        self.component_in_edit_mode.type === 'slider' || self.component_in_edit_mode.type === 'accordion' || self.component_in_edit_mode.type === 'view' ?
                            <AddComponentModal childMode parentID={ self.component_in_edit_mode.original_id } />
                        : null
                    }
                    
                    {/* Directional controls */}
                    <div>
                        <Button onClick={() => self.moveComponent('up', self.component_in_edit_mode.original_id)} className={styles.controller_button}><Icon name='arrow up' className={styles.controller_button_icon} /> Move up</Button>
                        <Button onClick={() => self.moveComponent('down', self.component_in_edit_mode.original_id)} className={styles.controller_button}><Icon name='arrow down' className={styles.controller_button_icon} /> Move down</Button>
                    </div>

                    <div className={ styles.editComponentWindowActions }>
                        {/* DANGER ZONE: DELETE COMPONENT */}
                        <Button color='red' onClick={() => {
                            if (confirm('Are you sure?')) {
                                self.deleteComponent(self.component_in_edit_mode.original_id)
                            }
                        }} className={[controlStyles.controller_button, controlStyles.controller_button_warning, styles.editComponentWindowActionsButton]} size="mini"><Icon name='delete' className={controlStyles.controller_button_icon} /> Delete Component</Button>
                    </div>
                </div>
            </div>
            :
            AppModel.in_menu_edit_mode ?
                <div className={`${styles.editComponentWindow} ${ styles.editComponentWindowActive }`} onClick={(ev) => AppModel.exitMenuEditMode() }>
                    <div className={`${styles.editComponentWindowInner}`} onClick={(ev) => ev.stopPropagation()}>
                        <Button className={ styles.editComponentWindowClose } onClick={() => AppModel.exitMenuEditMode() }>
                            <Icon name='arrow right' className={styles.editComponentWindowCloseIcon} />
                        </Button>
                        {/* Title */}
                        <h5 className={`${styles.editComponentWindowComponentName} bg-gradient-to-r from-rose-700 to-pink-600`}>
                            <Icon name={ 'bars' } className={controlStyles.controller_button_icon} /> Menu Settings
                        </h5>

                        <Accordion.Accordion panels={ self.renderEditMenuPanels() } style={{ marginBottom: 40 }} />
                    </div>
                </div>
            :
            <div className={`${editComponentWindowStyles.editComponentWindow} ${ editComponentWindowStyles.editComponentWindowActive }`}>
                <div className={`${editComponentWindowStyles.editComponentWindowInner}`} onClick={(ev) => ev.stopPropagation()}>
                    {
                        <div style={{ paddingTop: 40 }}>
                            <h3>Choose an existing component to edit or add a new component to this screen.</h3>
                            <p className="bg-clip-text text-transparent bg-gradient-to-r from-pink-600 to-pink-600">
                                Tip: new components are added to the bottom of this current screen.
                            </p>
                            <AddComponentModal />
                        </div>
                    }

                    {/* SCREEN PROPERTIES */}
                    {
                        MODEL.current_screen ? 
                            <div className={`${editComponentWindowStyles.editComponentWindowScreenSettings}`}>
                                <h4 className={ styles.editComponentWindowFieldContainerHeadline } style={{ marginBottom: 20 }}>Screen Properties</h4>
                                {
                                    MODEL.current_screen.original_id !== 'HOME' && MODEL.current_screen.original_id !== 'HEADER' && MODEL.current_screen.original_id !== 'FOOTER' && MODEL.current_screen.original_id !== 'MENU' && MODEL.current_screen.original_id !== 'LOGIN_WALL' && MODEL.current_screen.original_id !== 'PREMIUM_WALL' ?
                                        <div>
                                            <h4 className={ styles.editComponentWindowFieldContainerHeadline }>Name</h4>
                                            <div className={styles.editComponentWindowFieldContainer}>
                                                <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelText]}>
                                                    <Input disabled={ MODEL.current_screen.original_id === 'HOME' || MODEL.current_screen.original_id.toLowerCase() === 'header' || MODEL.current_screen.original_id.toLowerCase() === 'footer' || MODEL.current_screen.original_id === 'MENU' || MODEL.current_screen.original_id.toLowerCase() === 'login_wall' || MODEL.current_screen.original_id.toLowerCase() === 'premium_wall' ? true : false } type='text' name="destination" value={ MODEL.current_screen.original_id } onChange={ (e, data) => { MODEL.updateCurrentScreenAttribute('original_id', data.value) } } className={styles.editComponentWindowFieldListItemLabelInputText} />
                                                </Label>
                                            </div>
                                        </div>
                                    : null
                                }
                                <div>
                                    <h4 className={ styles.editComponentWindowFieldContainerHeadline }>Type</h4>
                                    <div className={styles.editComponentWindowFieldContainer}>
                                        <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelText]}>
                                            <Select placeholder='Select a screen type' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={
                                                [
                                                    { key: 'screen', value: 'screen', text: 'Screen' },
                                                    { key: 'modal', value: 'modal', text: 'Modal' }
                                                ]
                                            } value={ MODEL.current_screen.type } onChange={ (e, data) => { MODEL.updateCurrentScreenAttribute('type', data.value) } } />
                                        </Label>
                                    </div>
                                </div>
                                <div>
                                    <h4 className={ styles.editComponentWindowFieldContainerHeadline }>User Level</h4>
                                    <div className={styles.editComponentWindowFieldContainer}>
                                        <Label className={[styles.editComponentWindowFieldListItemLabel, styles.editComponentWindowFieldListItemLabelText]}>
                                            <Dropdown multiple placeholder='Select a user level' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={
                                                MODEL.user_levels.map((user_level) => {
                                                    let level = user_level.get_computed_level()
                                                    return { key: level.id, value: level.id, text: level.label }
                                                })
                                            } defaultValue={ MODEL.current_screen.user_levels.map((user_level) => { console.log('HERE_1', user_level.id); return `${user_level.id}` }) } onChange={ (e, data) => { MODEL.updateCurrentScreenAttribute('user_level', data.value) } } />
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        : null
                    }

                    <hr style={{ borderWidth: 1, borderColor: '#1B2533' }} />

                    <div className={`${editComponentWindowStyles.editComponentWindowScreenSettings}`}>
                        <h4 className={ styles.editComponentWindowFieldContainerHeadline } style={{ marginBottom: 20 }}>Status Bar</h4>
                        <Select placeholder='Should status bar?' className={styles.editComponentWindowFieldListItemLabelInputSelect} options={
                            [
                                { key: 'true', value: true, text: 'Show' },
                                { key: 'false', value: false, text: 'Hide' }
                            ]
                        } value={ MODEL.show_status_bar } onChange={ (e, data) => { MODEL.shouldShowStatusBar(data.value) } } />
                    </div>
                    
                </div>
            </div>
        }
    }))

function arrayMove(arr, fromIndex, toIndex) {

    var component = arr[fromIndex];

    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, component);

    return arr
}

export default Screen;
