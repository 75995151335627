import MODEL from '../../model/app';

export default function prepareStyles(styles) {
    let _styles = {}

    Object.keys(styles).forEach((key) => {
        // Kick out any legacy styling (margin- and padding- are invalid - they must be marginTop, paddingTop etc.)
        if (key !== 'margin-top' && key !== 'margin-bottom' && key !== 'margin-right' && key !== 'margin-left' && key !== 'padding-top' && key !== 'padding-bottom' && key !== 'padding-right' && key !== 'padding-left' && key !== 'font-size' && key !== 'letter-spacing' && key !== 'line-height') {
            _styles[key] = styles[key]
        }
    })

    // Everything must be flex and relative
    _styles.position = typeof _styles.position !== 'undefined' ? _styles.position : 'relative'
    _styles.display = 'flex'

    // Convert any background media IDs to real source locations
    if (_styles.backgroundImage) {
        _styles.backgroundImage = `url("${MODEL.getMediaItemLocationByID(_styles.backgroundImage)}")`
    }

    // widths and height must be converted from custom sizing measurement into percentage of the device model.)
    let device = document.querySelector('[data-device]')
    
    let deviceWidth = 330
    let deviceHeight = 660
    
    _styles.width = `${deviceWidth / 100 * parseFloat(styles.width) * 1}px`;
    _styles.height = `${deviceHeight / 100 * parseFloat(styles.height) * 1}px`;

    _styles.marginTop = `${deviceHeight / 100 * parseFloat(styles.marginTop) * 1}px`;
    _styles.marginRight = `${deviceWidth / 100 * parseFloat(styles.marginRight) * 1}px`;
    _styles.marginBottom = `${deviceHeight / 100 * parseFloat(styles.marginBottom) * 1}px`;
    _styles.marginLeft = `${deviceWidth / 100 * parseFloat(styles.marginLeft) * 1}px`;

    _styles.paddingTop = `${deviceHeight / 100 * parseFloat(styles.paddingTop) * 1}px`;
    _styles.paddingRight = `${deviceWidth / 100 * parseFloat(styles.paddingRight) * 1}px`;
    _styles.paddingBottom = `${deviceHeight / 100 * parseFloat(styles.paddingBottom) * 1}px`;
    _styles.paddingLeft = `${deviceWidth / 100 * parseFloat(styles.paddingLeft) * 1}px`;

    _styles.fontSize = `${deviceWidth / 100 * parseFloat(styles.fontSize) * 1}px`;
    _styles.lineHeight = `${deviceWidth / 100 * parseFloat(styles.lineHeight) * 1}px`;

    if (_styles.top) {
        _styles.top = `${deviceHeight / 100 * parseFloat(styles.top) * 1}px`;
    }

    if (_styles.left) {
        _styles.left = `${deviceHeight / 100 * parseFloat(styles.left) * 1}px`;
    }

    if (_styles.right) {
        _styles.right = `${deviceHeight / 100 * parseFloat(styles.right) * 1}px`;
    }

    if (_styles.bottom) {
        _styles.bottom = `${deviceHeight / 100 * parseFloat(styles.bottom) * 1}px`;
    }

    return _styles
}