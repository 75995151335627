import { observer } from 'mobx-react'
import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import AppModel from '../model/app'
import USER from '../model/user'

export default observer(function PaymentMethodModal(props) {
  const [open, setOpen] = React.useState(false)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button style={{ width: '100%', height: 50, borderRadius: 0 }}>{ props.title }</Button>}
    >
      <Modal.Header>
        Please choose a payment method
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className={ USER.is_paying ? `upgrade-payment-is-paying` : `` }>
            <div className='upgrade-payment-method-list'>
                <a href="#" className='upgrade-payment-method' onClick={(ev) => { ev.preventDefault(); USER.upgrade(1, 1).then(() => setOpen(false)); }}>
                    Payment Method 1
                </a>
            </div>

            {/* <div style={{ display: 'flex', marginTop: 30, marginBottom: 30, justifyContent: 'flex-end' }}>
              <Button onClick={() => { }}>View Invoices</Button>
              <Button onClick={() => { }}>Cancel Subscription</Button>
            </div> */}
            
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='white' onClick={() => setOpen(false)}>
          Back  
        </Button>
      </Modal.Actions>
    </Modal>
  )
})