import Component from '../model/types/component';
import ComponentAttributes from '../model/types/componentAttributes';

export default {
    login: {
        options: [
            {
                type: 'facebook'
            },
            {
                type: 'google'
            }
        ],
        style: {
            "display": "flex",
            "justify-content": "flex-start",
            "align-items": "flex-start",
            "margin": [ "0px", "0px", "0px", "0px" ],
            "padding": [ "0px", "0px", "0px", "0px" ],
            "width": '',
            "height": '',
            "backgroundColor": "#fff",
            "rotate": "0px"
        },
        accessRules: {
            public: true
        },
        components: [
            {
                type: 'text',
                attributes: {
                    text: "Sample text",
                    style: {
                        "color": "#000",
                        "fontFamily": "Arial",
                        "fontSize": "14px",
                        "fontStyle": "normal",
                        "fontWeight": "normal",
                        "textAlign": "left",
                        "letterSpacing": 2,
                        "lineHeight": 27,
                        "backgroundColor": "transparent"
                    }
                }
            }
        ]
    },
    login_google: {
        id: '',
        type: 'login_google',
        attributes: {
            theme: 'red',
            styles: JSON.stringify({
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0
            })
        }
    },
    login_facebook: {
        id: '',
        type: 'login_facebook',
        attributes: {
            styles: JSON.stringify({
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0
            })
        }
    },
    text: {
        id: '',
        type: 'text',
        attributes: {
            text: 'Sample text',
            layout: 'middle',
            destination: null,
            destination_type: null,
            styles: JSON.stringify({
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": 100,
                "height": 20,
                "color": "#000",
                "fontFamily": "Arial",
                "fontSize": "21px",
                "fontStyle": "normal",
                "fontWeight": "normal",
                "textAlign": "left",
                "letterSpacing": 2,
                "lineHeight": 27,
                "textDecorationStyle": "solid",
                "textDecorationLine": "none",
                "textTransform": "none",
                "backgroundColor": "transparent",
                "rotate": "0px"
            })
        }
    },
    accordion: {
        id: '',
        type: 'accordion',
        attributes: {
            text: 'Tap on me to open',
            destination: null,
            destination_type: null,
            children: [
                {
                    id: '',
                    type: 'text',
                    attributes: {
                        text: 'hello!',
                        destination: null,
                        destination_type: null,
                        styles: JSON.stringify({
                            "display": "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "marginTop": 0,
                            "marginRight": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "paddingTop": 0,
                            "paddingRight": 0,
                            "paddingBottom": 0,
                            "paddingLeft": 0,
                            "width": '100%',
                            "height": '200px',
                            "color": "#000",
                            "fontFamily": "Arial",
                            "fontSize": "21px",
                            "fontStyle": "normal",
                            "fontWeight": "normal",
                            "textAlign": "left",
                            "letterSpacing": 2,
                            "lineHeight": 27,
                            "textDecorationStyle": "solid",
                            "textDecorationLine": "none",
                            "textTransform": "none",
                            "backgroundColor": "transparent",
                            "rotate": "0px"
                        })
                    }
                }
            ],
            styles: JSON.stringify({
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": '100%',
                "height": '200px',
                "color": "#000",
                "fontFamily": "Arial",
                "fontSize": "21px",
                "fontStyle": "normal",
                "fontWeight": "normal",
                "textAlign": "left",
                "letterSpacing": 2,
                "lineHeight": 27,
                "textDecorationStyle": "solid",
                "textDecorationLine": "none",
                "textTransform": "none",
                "backgroundColor": "transparent",
                "rotate": "0px"
            })
        }
    },
    video: {
        id: '',
        type: 'video',
        attributes: {
            autoplay: true,
            controls: false,
            muted: true,
            styles: JSON.stringify({
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": 100,
                "height": 40,
                "backgroundColor": "transparent",
                "rotate": "0px"
            })
        }
    },
    view: {
        id: '',
        type: 'view',
        attributes: {
            styles: JSON.stringify({
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": 100,
                "height": 50,
                "backgroundColor": "transparent",
                "rotate": "0px"
            })
        }
    },
    button: {
        id: '',
        type: 'button',
        attributes: {
            text: 'Click me!',
            destination: null,
            destination_type: null,
            styles: JSON.stringify({
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": 100,
                "height": 15,
                "color": "#000",
                "fontFamily": "Arial",
                "fontSize": "21px",
                "fontStyle": "normal",
                "fontWeight": "normal",
                "textAlign": "left",
                "letterSpacing": 2,
                "lineHeight": 27,
                "textDecorationStyle": "solid",
                "textDecorationLine": "none",
                "textTransform": "none",
                "backgroundColor": "transparent",
                "rotate": "0px"
            })
        }
    },
    image: {
        id: '',
        type: 'image',
        attributes: {
            source: '0',
            destination: null,
            destination_type: null,
            styles: JSON.stringify({
                "display": "flex",
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": 100,
                "height": 30,
                "backgroundColor": "transparent",
                "rotate": "0px"
            })
        }
    },
    slider: {
        id: '',
        type: 'slider',
        attributes: {
            styles: JSON.stringify({
                "display": "flex",
                "align-items": "center",
                "justify-content": "center",
                "marginTop": 0,
                "marginRight": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "paddingTop": 0,
                "paddingRight": 0,
                "paddingBottom": 0,
                "paddingLeft": 0,
                "width": 100,
                "height": 50,
                "color": "#000",
                "fontFamily": "Arial",
                "fontSize": "21px",
                "fontStyle": "normal",
                "fontWeight": "normal",
                "textAlign": "left",
                "letterSpacing": 2,
                "lineHeight": 27,
                "textDecorationStyle": "solid",
                "textDecorationLine": "none",
                "textTransform": "none",
                "backgroundColor": "transparent",
                "rotate": "0px"
            }),
            children: [
                {
                    text: {
                        id: '',
                        type: 'text',
                        attributes: {
                            text: 'Sample slider item text',
                            destination: null,
                            destination_type: null,
                            styles: JSON.stringify({
                                "display": "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                "marginTop": 0,
                                "marginRight": 0,
                                "marginBottom": 0,
                                "marginLeft": 0,
                                "paddingTop": 0,
                                "paddingRight": 0,
                                "paddingBottom": 0,
                                "paddingLeft": 0,
                                "width": 100,
                                "height": 20,
                                "color": "#000",
                                "fontFamily": "Arial",
                                "fontSize": "21px",
                                "fontStyle": "normal",
                                "fontWeight": "normal",
                                "textAlign": "left",
                                "letterSpacing": 2,
                                "lineHeight": 27,
                                "textDecorationStyle": "solid",
                                "textDecorationLine": "none",
                                "textTransform": "none",
                                "backgroundColor": "transparent",
                                "rotate": "0px"
                            })
                        }
                    }
                }
            ]
        }
    }
}