/*eslint require-yield: "off"*/
import { types, flow, getSnapshot } from 'mobx-state-tree'

import ComponentAttributes from './componentAttributes'

const User = types
  .model('User', {
    uid: types.string,
    email: types.maybeNull(types.string),
    email_verified: types.maybeNull(types.boolean),
    email: types.maybeNull(types.string),
    created: types.maybeNull(types.string)
  })
  .actions(self => ({
 
  }))
  .views(self => ({
    
  }))

export default User;
