/*eslint require-yield: "off"*/
import { types, flow, getSnapshot } from 'mobx-state-tree'

import ComponentAttributes from './componentAttributes'

const Component = types
  .model('Component', {
    id: types.string,
    original_id: types.maybeNull(types.string),
    parent_id: types.maybeNull(types.string),
    type: types.string,
    attributes: types.maybeNull(ComponentAttributes),
    children: types.maybeNull(types.string) // optional
  })
  .actions(self => ({
    setID: flow(function* (id) {
      self.id = id
    }),
    updateAttributes: flow(function* (attributes) {
      let individualAttributes = { id: attributes.id }
            
      Object.keys(attributes).map((key) => {
          if (key === 'styles') {
              individualAttributes[key] = attributes[key] // in this case, they are already stringified - do NOT stringify again.
          } else {
              individualAttributes[key] = attributes[key]
          }
      })

      attributes = ComponentAttributes.create(individualAttributes)

      self.attributes = attributes
    }),
    updateGrandChildAttributes: flow(function* (attributes, grandChildIndex, childIndex) {
      let individualAttributes = { id: attributes.id }
            
      Object.keys(attributes).map((key) => {
          if (key === 'styles') {
              individualAttributes[key] = attributes[key] // in this case, they are already stringified - do NOT stringify again.
          } else {
              individualAttributes[key] = attributes[key]
          }
      })

      attributes = ComponentAttributes.create(individualAttributes)

      // Find the grandchild to update.
      let children = JSON.parse(self.children)
      let grandchildren = JSON.parse(JSON.parse(self.children)[childIndex].children)
      let relevantGrandChild = grandchildren[grandChildIndex]

      if (relevantGrandChild) {
        
        // Update grandchild attributes
        grandchildren[grandChildIndex].attributes = attributes
        
        // Update child with their new grandchild(ren)
        children[childIndex].children = JSON.stringify(grandchildren)
      }

      self.children = JSON.stringify(children)
    }),
    updateChildAttributes: flow(function* (attributes, childIndex) {
      let individualAttributes = { id: attributes.id }
            
      Object.keys(attributes).map((key) => {
          if (key === 'styles') {
              individualAttributes[key] = attributes[key] // in this case, they are already stringified - do NOT stringify again.
          } else {
              individualAttributes[key] = attributes[key]
          }
      })

      attributes = ComponentAttributes.create(individualAttributes)

      // Find child to update.
      let children = JSON.parse(self.children)
      if (children.length > 0) {
        children[childIndex].attributes = attributes
      }

      self.children = JSON.stringify(children)
    }),
    updateChildStyles: flow(function* (styles, childIndex) {
      // Find child to update.
      let children = JSON.parse(self.children)
      if (children.length > 0) {
        children[childIndex].attributes.styles = JSON.stringify(styles)
      }

      self.children = JSON.stringify(children)
    }),
    updateGrandChildStyles: flow(function* (styles, grandChildIndex, childIndex) {

      // Find the grandchild to update.
      let children = JSON.parse(self.children)
      let grandchildren = JSON.parse(JSON.parse(self.children)[childIndex].children)
      let relevantGrandChild = grandchildren[grandChildIndex]

      if (relevantGrandChild) {
        
        // Update grandchild attributes
        grandchildren[grandChildIndex].attributes.styles = JSON.stringify(styles)
        
        // Update child with their new grandchild(ren)
        children[childIndex].children = JSON.stringify(grandchildren)
      }

      self.children = JSON.stringify(children)
    }),
    setLayout: flow(function* (layout) {
      self.attributes.layout = layout
    })
  }))
  .views(self => ({
    getAttributes() {
      return self.attributes.computed_attributes()
    },
    computed_children() {
      
      let children = []

      if (self.children) {

        JSON.parse(self.children).forEach((component) => {

          // Create instance from snapshot
          component = Component.create(component)

          // Prep attributes
          let individualAttributes = { id: component.id }
            
          Object.keys(component.getAttributes()).map((key) => {
              if (key === 'styles') {
                  individualAttributes[key] = JSON.stringify(component.getAttributes()[key])
              } else {
                  individualAttributes[key] = component.getAttributes()[key]
              }
          })
          
          let _attributes = ComponentAttributes.create(individualAttributes)

          let _children = []

          if (JSON.parse(component.children)) {
              
              JSON.parse(component.children).forEach((component) => {
                _children.push(getSnapshot(Component.create(component)))
              })
          }

          children.push(Component.create({
            id: `unique-${component.id}-${new Date().getTime()}`,
            original_id: component.original_id,
            parent_id: component.parent_id,
            type: component.type,
            attributes: _attributes,
            children: _children.length > 0 ? JSON.stringify(_children) : null
          }))
        })
      }

      return children
    }
  }))

export default Component;
