/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree'

import Component from './component';
import ComponentAttributes from './componentAttributes'

import { Button, Form, Icon, Select, Label, Input } from 'semantic-ui-react'

import attributesByComponent from '../../renderer/attributes'

const Menu = types
    .model('Menu', {
        type: types.optional(types.string, ''),
        drawer_position: types.optional(types.string, ''),
        should_bounce: types.optional(types.string, ''),
        tab: types.optional(types.string, ''),
        icon_position: types.optional(types.string, ''),
     })
    .actions(self => ({
        updateAttribute: flow(function* (key, value) {
            self[key] = value
        }),
        updateMenuTabPosition: flow(function* (key, value) {
            let tab = JSON.parse(self.tab)
            let position = tab.position

            position[key] = value

            tab.position = position

            self.tab = JSON.stringify(tab)

            window.dispatchEvent(new Event('resize'));
        }),
        updateMenuTabStyles: flow(function* (key, value) {
            let tab = JSON.parse(self.tab)
            let styles = JSON.parse(tab.styles)

            styles[key] = value

            tab.styles = JSON.stringify(styles)

            self.tab = JSON.stringify(tab)

            window.dispatchEvent(new Event('resize'));
        }),
        updateMenuTabCloseStyles: flow(function* (key, value) {
            let tab = JSON.parse(self.tab)
            let styles = JSON.parse(tab.close_styles)

            styles[key] = value

            tab.close_styles = JSON.stringify(styles)

            self.tab = JSON.stringify(tab)

            window.dispatchEvent(new Event('resize'));
        }),
        updateMenuTabIcon: flow(function* (id) {
            let tab = JSON.parse(self.tab)
            
            tab.source = id

            self.tab = JSON.stringify(tab)
        }),
        updateMenuTabCloseIcon: flow(function* (id) {
            let tab = JSON.parse(self.tab)
            
            tab.close_source = id

            self.tab = JSON.stringify(tab)
        }),
        updateStyles: flow(function* (styles) {
            self.styles = JSON.stringify(styles)
        }),
    }))
    .views(self => ({
        computed_styles() {
            return JSON.parse(JSON.parse(self.tab).styles)
        },
        computed_tab_position() {
            let position = JSON.parse(self.tab).position

            if (self.icon_position === 'top_left') {
                position.top = 0
                position.left = 0
                delete position.right
                delete position.bottom
            } else if (self.icon_position === 'top_right') {
                position.top = 0
                delete position.left
                position.right = 0
                delete position.bottom
            } else if (self.icon_position === 'bottom_left') {
                delete position.top
                position.left = 0
                delete position.right
                position.bottom = 0
            } else if (self.icon_position === 'bottom_right') {
                delete position.top
                delete position.left
                position.right = 0
                position.bottom = 0
            }

            return { ...position, position: 'absolute', zIndex: 99999}
        },
        computed_tab_icon() {
            return JSON.parse(self.tab).source
        },
        computed_tab_close_icon() {
            return JSON.parse(self.tab).close_source
        },
        computed_tab_close_position() {
            return JSON.parse(self.tab).close_position
        },
        computed_close_styles() {
            return JSON.parse(JSON.parse(self.tab).close_styles)
        },
        computed_attributes() {
            return {
                type: self.type
            }
        }
    }))

export default Menu;
