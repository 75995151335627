import { observer } from 'mobx-react'
import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Icon } from 'semantic-ui-react'

import optionsLogin from '../options/login'
import styles from '../styles/AddComponent.module.css'

import MODEL from '../model/app'

export default observer(function AddComponentModal(props) {
    const [open, setOpen] = React.useState(false)
    
    let newComponentType = null

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button key={`add_component_button`} color='green' className={ props.childMode ? styles.add_button_simple : styles.add_button }><Icon name='add' className={ props.childMode ? styles.add_button_icon_simple : styles.add_button_icon } />{ props.childMode ? <span>Add Item</span> : null }</Button>}
        >
        <Modal.Header>Add something new to this screen</Modal.Header>
        <Modal.Content image scrolling style={{ minHeight: '50vh' }}>
            <Modal.Description style={{ maxWidth: '100%' }}>
                <Header>Components</Header>

                <div className='media-list-container'>
                    <div className='media-list' style={{ flexWrap: 'wrap' }}>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'view').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('view').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='box' />
                            <span>Container</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'text').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('text').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='font' />
                            <span>Text</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'image').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('image').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='image' />
                            <span>Image</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'slider').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('slider').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='grab' />
                            <span>Slider</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'button').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('button').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='linkify' />
                            <span>Button</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'video').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('video').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='play' />
                            <span>Video</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'accordion').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('accordion').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='list' />
                            <span>Accordion</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'login_google').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('login_google').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='google' />
                            <span>Google Login</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'login_apple').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('login_apple').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='apple' />
                            <span>Apple Login</span>
                        </a>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'login_facebook').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('login_facebook').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='facebook' />
                            <span>Facebook Login</span>
                        </a>
                    </div>
                </div>

                <Header>Templates</Header>

                <div className='media-list-container'>
                    <div className='media-list' style={{ flexWrap: 'wrap' }}>
                        <a href="#" onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, 'view').then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen('view').then(() => setOpen(false)) }}>
                            <Icon style={{ fontSize: 48 }} name='box' />
                            <span>Container</span>
                        </a>
                    </div>
                </div>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
                Cancel
            </Button>
            {/* <Button
                content="Add Component"
                labelPosition='right'
                icon='arrow right'
                color='green'
                onClick={() => { props.parentID ? MODEL.addComponentToParentComponent(props.parentID, newComponentType).then(() => setOpen(false)) : MODEL.addComponentToCurrentScreen(newComponentType).then(() => setOpen(false)) }}
                positive
            /> */}
        </Modal.Actions>
        </Modal>
    )
})