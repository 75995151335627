/*eslint require-yield: "off"*/
import { types, flow, getSnapshot } from 'mobx-state-tree'

const UserLevel = types
  .model('UserLevel', {
    id: types.string,
    label: types.maybeNull(types.string)
  })
  .actions(self => ({
 
  }))
  .views(self => ({
    get_computed_level() {
      return {
        id: `${self.id}`,
        label: `${self.label}`
      }
    }
  }))

export default UserLevel;
