import React from 'react'
import { observer } from 'mobx-react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import AppModel from '../model/app'
import USER from '../model/user'
import MediaGalleryModal from './mediaGallery'
import MediaThumbnail from '../renderer/media/thumbnail'

export default observer(function UsersModal(props) {
  const [open, setOpen] = React.useState(typeof props.open !== 'undefined' ? props.open : false)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => { setOpen(false); props.onClose ? props.onClose() : null; }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button onClick={ () => AppModel.retrieveUsers() }>Users</Button>}
    >
      <Modal.Header>
        Users ({ AppModel.getUserCount() })
      </Modal.Header>
      <Modal.Content image scrolling style={{ minHeight: '50vh' }}>
        <Modal.Description>
          <div style={{ marginBottom: 30 }}>
            <Input name='search' type='text' style={{ width: '100%' }} placeholder='Search...' />
          </div>
          <div className='user-list'>
            { AppModel.getUserList() }
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='white' onClick={() => { setOpen(false); props.onClose ? props.onClose() : null; }}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
})