import React from 'react'
import { observer } from 'mobx-react'
import { Button, Header, Image, Modal, Form, Input, Select, Label } from 'semantic-ui-react'

import styles from '../styles/MenuModal.module.css'

import MODEL from '../model/app'

export default observer(function MenuModal() {
  const [open, setOpen] = React.useState(false)

  if (!MODEL.menu) {
    return <span></span>
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Menu Settings</Button>}
    >
      <Modal.Header>Global menu settings</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
            <Header>Type of menu</Header>
            <p>Are you looking for a drawer menu or modal menu?</p>
            <Form.Field>
                <Select placeholder='Type of screen' options={[
                    { key: 'drawer', value: 'drawer', text: 'Drawer' },
                    { key: 'modal', value: 'modal', text: 'Modal' },
                ]} defaultValue={ MODEL.menu.type } onChange={ (e, data) => { MODEL.menu.updateAttribute('type', data.value) } } />
            </Form.Field>
            {/* CONDITIONAL */}
            <Header>Drawer position</Header>
            <p>From which side of the screen should the drawer menu slide in from?</p>
            <Form.Field>
                <Select placeholder='Drawer position' options={[
                    { key: 'left', value: 'left', text: 'Left' },
                    { key: 'right', value: 'right', text: 'Right' },
                ]} defaultValue={ MODEL.menu.drawer_position } onChange={ (e, data) => { MODEL.menu.updateAttribute('drawer_position', data.value) } } />
            </Form.Field>
            <Header>Menu bouncing</Header>
            <p>Should the menu bounce on scrolling?</p>
            <Form.Field>
                <Select placeholder='Menu should bounce?' options={[
                    { key: 'ye', value: 'yes', text: 'Yes' },
                    { key: 'no', value: 'no', text: 'No' },
                ]} defaultValue={ MODEL.menu.should_bounce } onChange={ (e, data) => { MODEL.menu.updateAttribute('should_bounce', data.value) } } />
            </Form.Field>
            <Header>Menu icon</Header>
            <p>What should the menu button look like?</p>
            <Form.Group className={ styles.menuModalLabelList }>
              <Form.Field>
                  <label className={ styles.menuModalLabel }>
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGxpbmUgeDE9IjMiIHgyPSIyMSIgeTE9IjEyIiB5Mj0iMTIiLz48bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iNiIgeTI9IjYiLz48bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iMTgiIHkyPSIxOCIvPjwvc3ZnPg==" />
                    <Input className={ styles.menuModalLabelInput } type="radio" name="menu_icon" defaultValue="burger_normal" checked={ MODEL.menu.icon === 'burger_normal' ? 'checked' : '' } onClick={ (e) => { MODEL.menu.updateAttribute('icon', 'burger_normal') } } />
                  </label>
              </Form.Field>
              <Form.Field>
                  <label className={ styles.menuModalLabel }>
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzFfMV8iIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDE2OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxjaXJjbGUgY3g9IjIiIGN5PSI4IiByPSIyIi8+PGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjIiLz48Y2lyY2xlIGN4PSIxNCIgY3k9IjgiIHI9IjIiLz48L3N2Zz4=" />
                    <Input className={ styles.menuModalLabelInput } type="radio" name="menu_icon" defaultValue="dotted_horizontal" checked={ MODEL.menu.icon === 'dotted_horizontal' ? 'checked' : '' } onClick={ (e) => { MODEL.menu.updateAttribute('icon', 'dotted_horizontal') } } />
                  </label>
              </Form.Field>
              <Form.Field>
                  <label className={ styles.menuModalLabel }>
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzFfMV8iIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDE2OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGhlaWdodD0iNCIgd2lkdGg9IjQiLz48cmVjdCBoZWlnaHQ9IjQiIHdpZHRoPSI0IiB4PSI2Ii8+PHJlY3QgaGVpZ2h0PSI0IiB3aWR0aD0iNCIgeD0iMTIiLz48cmVjdCBoZWlnaHQ9IjQiIHdpZHRoPSI0IiB5PSI2Ii8+PHJlY3QgaGVpZ2h0PSI0IiB3aWR0aD0iNCIgeD0iNiIgeT0iNiIvPjxyZWN0IGhlaWdodD0iNCIgd2lkdGg9IjQiIHg9IjEyIiB5PSI2Ii8+PHJlY3QgaGVpZ2h0PSI0IiB3aWR0aD0iNCIgeT0iMTIiLz48cmVjdCBoZWlnaHQ9IjQiIHdpZHRoPSI0IiB4PSI2IiB5PSIxMiIvPjxyZWN0IGhlaWdodD0iNCIgd2lkdGg9IjQiIHg9IjEyIiB5PSIxMiIvPjwvc3ZnPg==" />
                    <Input className={ styles.menuModalLabelInput } type="radio" name="menu_icon" defaultValue="squared_thick" checked={ MODEL.menu.icon === 'squared_thick' ? 'checked' : '' } onClick={ (e) => { MODEL.menu.updateAttribute('icon', 'squared_thick') } } />
                  </label>
              </Form.Field>
              <Form.Field>
                  <label className={ styles.menuModalLabel }>
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDAwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4O308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGRhdGEtbmFtZT0iNjctbWVudSIgaWQ9Il82Ny1tZW51Ij48cmVjdCBjbGFzcz0iY2xzLTEiIGhlaWdodD0iMTIiIHdpZHRoPSIxMiIgeD0iMSIgeT0iMSIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgaGVpZ2h0PSIxMiIgd2lkdGg9IjEyIiB4PSIxIiB5PSIxOSIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgaGVpZ2h0PSIxMiIgd2lkdGg9IjEyIiB4PSIxOSIgeT0iMTkiLz48cmVjdCBjbGFzcz0iY2xzLTEiIGhlaWdodD0iMTIiIHdpZHRoPSIxMiIgeD0iMTkiIHk9IjEiLz48L2c+PC9zdmc+" />
                    <Input className={ styles.menuModalLabelInput } type="radio" name="menu_icon" defaultValue="squared_light" checked={ MODEL.menu.icon === 'squared_light' ? 'checked' : '' } onClick={ (e) => { MODEL.menu.updateAttribute('icon', 'squared_light') } } />
                  </label>
              </Form.Field>
            </Form.Group>
            <Header>Menu icon position</Header>
            <p>Where should the menu icon be positioned?</p>
            <Form.Field>
                <Select placeholder='Menu icon position' options={[
                    { key: 'top_left', value: 'top_left', text: 'Top-Left' },
                    { key: 'top_right', value: 'top_right', text: 'Top-Right' },
                    { key: 'bottom_left', value: 'bottom_left', text: 'Bottom-Left' },
                    { key: 'bottom_right', value: 'bottom_right', text: 'Bottom-Right' },
                ]} defaultValue={ MODEL.menu.icon_position } onChange={ (e, data) => { MODEL.menu.updateAttribute('icon_position', data.value) } } />
            </Form.Field>
            <Header>Menu icon margin</Header>
            <p>If you want to push the menu away from the edge, you can do so here:</p>
            <Form.Field>
                <label>
                  <span>Top Margin</span>
                  <Input type="text" defaultValue={ MODEL.menu.icon_margin_override_top } onChange={ (e, data) => { MODEL.menu.updateAttribute('icon_margin_override_top', data.value) } } />
                </label>
            </Form.Field>
            <Form.Field>
                <label>
                  <span>Left Margin</span>
                  <Input type="text" defaultValue={ MODEL.menu.icon_margin_override_left } onChange={ (e, data) => { MODEL.menu.updateAttribute('icon_margin_override_left', data.value) } } />
                </label>
            </Form.Field>
            <Form.Field>
                <label>
                  <span>Right Margin</span>
                  <Input type="text" defaultValue={ MODEL.menu.icon_margin_override_right } onChange={ (e, data) => { MODEL.menu.updateAttribute('icon_margin_override_right', data.value) } } />
                </label>
            </Form.Field>
            <Form.Field>
                <label>
                  <span>Bottom Margin</span>
                  <Input type="text" defaultValue={ MODEL.menu.icon_margin_override_bottom } onChange={ (e, data) => { MODEL.menu.updateAttribute('icon_margin_override_bottom', data.value) } } />
                </label>
            </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
})