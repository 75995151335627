import { observer } from 'mobx-react'
import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Label } from 'semantic-ui-react'

import MediaUploadModal from './mediaUpload';

import AppModel from '../model/app'

export default observer(function MediaGalleryModal(props) {
  const [open, setOpen] = React.useState(typeof props.open !== 'undefined' ? props.open : false)

  return (
    <Modal
      onClose={() => { AppModel.setActiveMediaInDetailMode(null); setOpen(false); props.onClose ? props.onClose() : null; }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color={ props.attribute === 'push_notification_image' ? 'black' : 'white' } onClick={ () => AppModel.retrieveMediaLibrary() }>{ props.componentInEditMode ? 'My Assets' : 'My Assets' }</Button>}
    >
      <Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          My Assets
          <div style={{ position: 'absolute', top: 12, right: 20 }}><MediaUploadModal /></div>
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
            <div className='media-list-container' style={ AppModel.getActiveMediaItemDetails() ? { paddingRight: '300px', minHeight: 450 } : { minHeight: 450 } }>
                <div className='media-list'>
                    { AppModel.getMediaLibraryList() }
                </div>
                
                {
                    AppModel.getActiveMediaItemDetails() ? 
                        <div className='media-list-details'>
                            <div className='media-list-details-image'>
                                {
                                    AppModel.getActiveMediaItemDetails().type === 'video/mp4' ? <video src={`${AppModel.getActiveMediaItemDetails().location}`} autoPlay={false} muted loop={true} playsinline controls width="100%" height="100%" /> : null
                                }
                                {
                                    AppModel.getActiveMediaItemDetails().type === 'image/jpeg' || AppModel.getActiveMediaItemDetails().type === 'image/png' || AppModel.getActiveMediaItemDetails().type === 'image/gif' || AppModel.getActiveMediaItemDetails().type === 'image/bmp' || AppModel.getActiveMediaItemDetails().type === 'image/svg+xml' ? <img src={ AppModel.getActiveMediaItemDetails().location } style={{ width: '100%', height: 'auto' }} /> : null
                                }
                                
                            </div>
                        
                            <Form.Field>
                                <label>
                                    <Label>Label</Label>
                                    <Input disabled type="text" value={ AppModel.getActiveMediaItemDetails().label } />
                                </label>
                            </Form.Field>
                            <Form.Field>
                                <label>
                                    <Label>Date</Label>
                                    <Input disabled type="text" value={ new Date(parseFloat(AppModel.getActiveMediaItemDetails().date)) } />
                                </label>
                            </Form.Field>
                            <Form.Field>
                                <label>
                                    <Label>Type</Label>
                                    <Input disabled type="text" value={ AppModel.getActiveMediaItemDetails().type } />
                                </label>
                            </Form.Field>

                            <div style={{ marginTop: 20 }}>
                                <Button color='red' size='mini' onClick={() => AppModel.deleteMediaItem(AppModel.getActiveMediaItemDetails().id) }>Delete</Button>
                            </div>
                        </div>
                    : null
                }
            </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }}>
        {
            props.componentInEditMode ? 
            <Button color='white' onClick={() => { AppModel.setActiveMediaInDetailMode(null); setOpen(false); props.onClose ? props.onClose() : null; }}>
                Go Back
            </Button>
            : 
            <Button color='white' onClick={() => { AppModel.setActiveMediaInDetailMode(null); setOpen(false); props.onClose ? props.onClose() : null; }}>
                Close
            </Button>
        }

        {
            props.componentInEditMode ? 
            <Button color='green' onClick={() => { 
                if (props.attribute === 'source') {
                    AppModel.current_screen.updateComponentAttribute(AppModel.current_screen.component_in_edit_mode, 'source', AppModel.getActiveMediaItemDetails().location);
                } else if (props.attribute === 'poster') {
                    AppModel.current_screen.updateComponentAttribute(AppModel.current_screen.component_in_edit_mode, 'poster', AppModel.getActiveMediaItemDetails().location);
                } else if (props.attribute === 'backgroundImage') {
                    AppModel.current_screen.updateComponentStyleAttribute(AppModel.current_screen.component_in_edit_mode, 'backgroundImage', AppModel.getActiveMediaItemDetails().id)
                } else if (props.attribute === 'menu_icon') {
                    AppModel.menu.updateMenuTabIcon(AppModel.getActiveMediaItemDetails().id)
                } else if (props.attribute === 'menu_icon_close') {
                    AppModel.menu.updateMenuTabCloseIcon(AppModel.getActiveMediaItemDetails().id)
                } else if (props.attribute === 'push_notification_image') {
                    AppModel.updatePushNotificationAttribute('image', AppModel.getActiveMediaItemDetails().id)
                }
                setOpen(false)
                props.onClose ? props.onClose() : null;
            }}>
                Use this asset
            </Button>
            : 
            null
        }

      </Modal.Actions>
    </Modal>
  )
})