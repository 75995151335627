import React from 'react'
import { observer } from 'mobx-react'

import MODEL from '../model/app';

import CustomComponent_Video from './components/video'
import CustomComponent_Text from './components/text'
import CustomComponent_Image from './components/image'
import CustomComponent_Slider from './components/slider'
import CustomComponent_Button from './components/button'
import CustomComponent_View from './components/view'
import CustomComponent_Accordion from './components/accordion'
import CustomComponent_Login_Apple from './components/login_apple'
import CustomComponent_Login_Facebook from './components/login_facebook'
import CustomComponent_Login_Google from './components/login_google'

import prepareStyles from './styles/renderer'

const Renderer = observer(
    class Renderer extends React.Component {
        render() {
            
            return <div style={{ position: 'relative' }}>

                {/* HEADER */}
                <div data-highlight-disabled={true} style={{ position: 'relative', zIndex: 9999 }}>
                    {
                        !this.props.screen.is_menu && this.props.screen.original_id.toLowerCase() !== 'header' && this.props.screen.original_id.toLowerCase() !== 'footer' && this.props.header && this.props.header.getComponents() ? (
                            this.props.header.getComponents().map((component) => {
                                return renderComponent(component, this.props)
                            })
                        ) : null
                    }
                </div>

                {/* FOOTER (needs special top spacing to ensure fully editable) */}
                { this.props.screen.original_id.toLowerCase() === 'footer' ? <div className='footer-buffer'></div> : null }

                {/* BODY */}
                {
                    this.props.screen && this.props.screen.getComponents() ? (
                        this.props.screen.getComponents().map((component) => {
                            return renderComponent(component, this.props)
                        })
                    ) : null
                }


                <div data-highlight-disabled={true}>
                    {
                        !this.props.screen.is_menu && this.props.screen.original_id.toLowerCase() !== 'header' && this.props.screen.original_id.toLowerCase() !== 'footer' && this.props.footer && this.props.footer.getComponents() ? (
                            this.props.footer.getComponents().map((component) => {
                                return renderComponent(component, this.props)
                            })
                        ) : null
                    }
                </div>
            </div>
        }
    }
)

export const renderComponent = (component, props) => {
    switch (component.type) {
        case "video":
            return <CustomComponent_Video openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } id={ component.attributes.id } handleClick={(ev) => { console.log('CLICK DETECTED: text', component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "text":
            return <CustomComponent_Text openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } id={ component.attributes.id } handleClick={(ev) => { console.log('CLICK DETECTED: text', component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "image":
            return <CustomComponent_Image hover={ component.hover } setHover={(bool) => component.setHover(bool)} openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } id={ component.attributes.id } handleClick={(ev) => { console.log('CLICK DETECTED: image'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "slider":
            return <CustomComponent_Slider originalProps={ props } openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } id={ component.attributes.id } handleClick={(ev) => { console.log('CLICK DETECTED: slider'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "button":
            return <CustomComponent_Button openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } id={ component.attributes.id } handleClick={(ev) => { console.log('CLICK DETECTED: button'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "view":
            return <CustomComponent_View originalProps={ props } openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } id={ component.attributes.id } handleClick={(ev) => { console.log('CLICK DETECTED: view'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "accordion":
            return <CustomComponent_Accordion originalProps={ props } openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } label={ component.attributes.label } handleClick={(ev) => { console.log('CLICK DETECTED: accordion'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} id={ component.attributes.id } attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "login_apple":
            return <CustomComponent_Login_Apple openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } login={ component.attributes.login } handleClick={(ev) => { console.log('CLICK DETECTED: login_apple'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} id={ component.attributes.id } attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "login_facebook":
            return <CustomComponent_Login_Facebook openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } login={ component.attributes.login } handleClick={(ev) => { console.log('CLICK DETECTED: login_facebook'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} id={ component.attributes.id } attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        case "login_google":
            return <CustomComponent_Login_Google openEditMode={() => props.screen.openEditMode(component)} moveComponent={(direction) => props.screen.moveComponent(direction, component.id)} deleteComponent={() => props.screen.deleteComponent(component.id)} duplicateComponent={() => props.screen.duplicateComponent(component.id)} style={ component.attributes.computed_styles() } login={ component.attributes.login } handleClick={(ev) => { console.log('CLICK DETECTED: login_google'); ev.stopPropagation(); props.screen.handleClick(ev, component.attributes.destination_type, component.attributes.destination, component.original_id, component.parent_id)}} id={ component.attributes.id } attributes={ component.attributes.computed_attributes() } children={ component.computed_children() } />
            break;
        default:
            return <p>Unhandled component</p>
    }
}

export default Renderer;