import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import PaymentMethodModal from './paymentMethod';

import AppModel from '../model/app'
import USER from '../model/user'
import InvoicesModal from './invoices';

function BuyCreditsSuccessModal(props) {
  const [open, setOpen] = React.useState(true)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => { setOpen(true) }}
      open={ open }
      trigger={<Button>Upgrade</Button>}
    >
      <Modal.Header>
        {/* <Button style={{ position: 'absolute', top: 12, right: 20 }} color='green' onClick={() => USER.logout().then(() => setOpen(false))}>Log out</Button> */}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
            <Header className='upgrade-header'>
              Purchase successful! ✌️
            </Header>

            <div style={{ paddingTop: 20, textAlign: 'center' }}>
              <p>You now have <strong>{ USER.credits } credits!</strong></p>
            </div>

            <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
              <Button color='green' onClick={() => { setOpen(false) }}>Let's get going!</Button>
              <InvoicesModal />
            </div>
            
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {/* <Button color='white' onClick={() => setOpen(false)}>
          Close
        </Button> */}
      </Modal.Actions>
    </Modal>
  )
}

export default BuyCreditsSuccessModal