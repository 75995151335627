/*eslint require-yield: "off"*/
import { types, flow, getSnapshot } from 'mobx-state-tree'

import ComponentAttributes from './componentAttributes'

const MediaItem = types
  .model('MediaItem', {
    id: types.string,
    label: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    encoding: types.maybeNull(types.string),
  })
  .actions(self => ({
 
  }))
  .views(self => ({
    
  }))

export default MediaItem;
