import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import PaymentMethodModal from './paymentMethod';

import AppModel from '../model/app'
import USER from '../model/user'

function UpgradeModal(props) {
  const [open, setOpen] = React.useState(false)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={ props.canNotClose ? true : open }
      trigger={<Button color='green'>Upgrade</Button>}
    >
      <Modal.Header>
        Hello, { USER.getDisplayName() } 👋 
        { props.canNotClose ? <Button style={{ position: 'absolute', top: 12, right: 20 }} color='green' onClick={() => USER.logout().then(() => setOpen(false))}>Log out</Button> : null }
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
            <Header className='upgrade-header'>
                Subscribe now to access all features
                <a href="#" className='upgrade-header-link'>Have you recently subscribed? If so, click here.</a>
            </Header>
            {/* <p>You are currently on the <strong>Personal Plan</strong> ($20 per month).</p> */}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 450 }}>
              <a href="#" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '23%', height: '100%', backgroundColor: 'white' }}>
                <strong className="bg-gradient-to-r from-rose-700 to-pink-600" style={{ paddingTop: 10, paddingBottom: 10, color: 'white', textAlign: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>Personal</strong>
                <ul style={{ margin: 0, listStyle: 'inside', padding: 10, color: 'black', fontSize: 13, lineHeight: 1.75, height: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>
                  <li>Standard templates</li>
                  <li>Standard components</li>
                  <li>Standard icons</li>
                  <li>Standard fonts</li>
                  <li>Email support</li>
                  <li>1 App Store update per month</li>
                </ul>
                <div style={{ backgroundColor: '#14171F' }}><Button onClick={(ev) => { ev.preventDefault(); USER.upgrade(1, 1); }} style={{ width: '100%', height: 50, borderRadius: 0 }}>$80/month</Button></div>
              </a>
              <a href="#" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '23%', height: '100%', backgroundColor: 'white' }}>
                <strong className="bg-gradient-to-r from-green-500 to-green-700" style={{ paddingTop: 10, paddingBottom: 10, color: 'white', textAlign: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>Business</strong>
                <ul style={{ margin: 0, listStyle: 'inside', padding: 10, color: 'black', fontSize: 13, lineHeight: 1.75, height: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>
                  <li>Pro templates</li>
                  <li>Pro components</li>
                  <li>Pro icons</li>
                  <li>Pro fonts</li>
                  <li>Social login</li>
                  <li>Email support</li>
                  <li>5 Push Notifications (per month)</li>
                  <li>4 App Store updates per month</li>
                </ul>
                <div style={{ backgroundColor: '#14171F' }}><Button onClick={(ev) => { ev.preventDefault(); USER.upgrade(2, 1); }} style={{ width: '100%', height: 50, borderRadius: 0 }}>$200/month</Button></div>
              </a>
              <a href="#" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '23%', height: '100%', backgroundColor: 'white' }}>
                <strong className="bg-gradient-to-r from-gray-700 via-gray-900 to-black" style={{ paddingTop: 10, paddingBottom: 10, color: 'white', textAlign: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>Business Pro</strong>
                <ul style={{ margin: 0, listStyle: 'inside', padding: 10, color: 'black', fontSize: 13, lineHeight: 1.75, height: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>
                  <li>Business templates</li>
                  <li>Business components</li>
                  <li>Business icons</li>
                  <li>Business fonts</li>
                  <li>Custom fonts</li>
                  <li>Social login</li>
                  <li>Animations</li>
                  <li>Accept Payments</li>
                  <li>Email / Chat support</li>
                  <li>Unlimited Push Notifications</li>
                  <li>Unlimited App Store updates per month</li>
                </ul>
                <div style={{ backgroundColor: '#14171F' }}><Button onClick={(ev) => { ev.preventDefault(); USER.upgrade(3, 1); }} style={{ width: '100%', height: 50, borderRadius: 0 }}>$400/month</Button></div>
              </a>
              <a href="#" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '23%', height: '100%', backgroundColor: 'white' }}>
                <strong className="bg-gradient-to-r from-red-500 to-red-800" style={{ paddingTop: 10, paddingBottom: 10, color: 'white', textAlign: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>Enterprise</strong>
                <ul style={{ margin: 0, listStyle: 'inside', padding: 10, color: 'black', fontSize: 13, lineHeight: 1.75, height: '100%', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>
                  <li>Your dream app, just as you imagine it</li>
                  <li>99.99% SLA</li>
                  <li>Dedicated Project Manager</li>
                  <li>Made in Germany</li>
                  <li>Email / Chat / Telephone support</li>
                </ul>
                <div style={{ backgroundColor: '#14171F' }}><Button style={{ width: '100%', height: 50, borderRadius: 0 }} color='white' onClick={() => setOpen(false)}>Get in touch</Button></div>
              </a>
            </div>

            {/* <div style={{ display: 'flex', marginTop: 30, marginBottom: 30, justifyContent: 'flex-end' }}>
              <Button onClick={() => { }}>View Invoices</Button>
              <Button onClick={() => { }}>Cancel Subscription</Button>
            </div> */}
            
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {
          !props.canNotClose ? 
            <Button color='white' onClick={() => setOpen(false)}>
              Close
            </Button>
          : null
        }
      </Modal.Actions>
    </Modal>
  )
}

export default UpgradeModal