import { observer } from 'mobx-react'
import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Label } from 'semantic-ui-react'
import Dropzone from 'react-dropzone-uploader'

import MediaUploadModal from './mediaUpload';

import AppModel from '../model/app'
import User from '../model/user'

import API from '../service/api'

export default observer(function MediaUploadModal(props) {
  const [open, setOpen] = React.useState(typeof props.open !== 'undefined' ? props.open : false)
  const [status, setStatus] = React.useState('')

  return (
    <Modal
      onClose={() => { setOpen(false); props.onClose ? props.onClose() : null; }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color='green'>{ 'Upload' }</Button>}
    >
      <Modal.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        Upload
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description data-status={ status }>
          <Upload setOpen={(bool) => { setOpen(bool); if (!bool) { props.onClose ? props.onClose() : null; } } } setStatus={(string) => setStatus(string) } />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }}>

        <Button color='black' onClick={() => { setOpen(false); props.onClose ? props.onClose() : null;  }}>
            Cancel
        </Button>

      </Modal.Actions>
    </Modal>
  )
})

const Upload = (props) => {
    const getUploadParams = ({ file, meta }) => {
      const body = new FormData()

      body.append('token', User.access_token)
      body.append('file', file)

      return { url: `${API.base}/media/add?token=${User.access_token}`, body }
    }
  
    const handleChangeStatus = ({ meta }, status) => {
      props.setStatus(status)

      if (status === 'done') {
        props.setStatus('done')

        // TODO: set loading state? might be slow?
        AppModel.retrieveMediaLibrary()
        
        props.setOpen(false)
      }
    }
  
    const handleSubmit = (files, allFiles) => {
      allFiles.forEach(f => f.remove())
    }
  
    return (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept="image/*,audio/*,video/*"
        multiple={false}
        inputContent={(files, extra) => (extra.reject ? 'Image, audio and video files only' : 'Drag Files')}
        styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
      />
    )
}