import React from 'react'
import { observer } from 'mobx-react'
import { Button, Header, Image, Modal, Form, Input, Select, Label, Icon } from 'semantic-ui-react'
import AppModel from '../model/app'

import USER from '../model/user'

export default observer(function PipelineModal() {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>App Store Status</Button>}
    >
      <Modal.Header>
        App Store Status
      </Modal.Header>
      <Modal.Content image scrolling style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {
          AppModel.get_computed_pipeline() && AppModel.get_computed_pipeline().ios.queue ?
            <Modal.Description style={{ display: 'flex', marginBottom: 40, width: '100%', justifyContent: 'center' }}>
                <Label color='lightgrey' style={{ marginBottom: 10 }}><Icon name='refresh' /> This page automatically updates.</Label>
            </Modal.Description>
          :
            null
        }
        <Modal.Description style={{ maxWidth: '45%' }}>
            {
              AppModel.get_computed_pipeline() ?
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 10 }}>
                      iOS Status
                      { !AppModel.get_computed_pipeline().ios.queue ? <Button onClick={() => USER.startBuild('ios')} size='small' color='black'>Start iOS Build</Button> : <Button onClick={() => USER.stopBuild('ios')} size='small' color='red'>Cancel iOS Build</Button> }
                    </Header>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      <Icon name='send' style={{ marginBottom: 40, fontSize: 48 }} />
                      We have sent your app to Apple for approval. Once this process is complete, your app will appear in the app store.<br/><br/>Check back later or wait for an email from us! ✌️
                    </div>

                    {/* <Label color={ AppModel.get_computed_pipeline().ios.queue && AppModel.get_computed_pipeline().ios.building ? 'green' : AppModel.get_computed_pipeline().ios.queue ? 'orange' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='clock' /> Waiting in the queue</Label>
                    <Label color={ AppModel.get_computed_pipeline().ios.building && AppModel.get_computed_pipeline().ios.testing ? 'green' : AppModel.get_computed_pipeline().ios.building ? 'orange' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='play' /> Building your app</Label>
                    <Label color={ AppModel.get_computed_pipeline().ios.testing && AppModel.get_computed_pipeline().ios.testflight ? 'green' : AppModel.get_computed_pipeline().ios.testing ? 'orange' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='eye' /> Testing your app</Label>
                    <Label color={ AppModel.get_computed_pipeline().ios.testing && !AppModel.get_computed_pipeline().ios.testflight ? `orange` : AppModel.get_computed_pipeline().ios.testflight ? 'green' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='send' /> { `Sending your app to TestFlight` } { AppModel.get_computed_pipeline().ios.testing && !AppModel.get_computed_pipeline().ios.testflight ? <span><Button color='black' style={{ marginTop: 10 }}><Icon name='check' /> Send my app to TestFlight</Button> <Label>more info</Label></span> : null }</Label>
                    <Label color={ AppModel.get_computed_pipeline().ios.testflight && !AppModel.get_computed_pipeline().ios.appstore ? `orange` : AppModel.get_computed_pipeline().ios.appstore ? 'green' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='send' /> { AppModel.get_computed_pipeline().ios.testflight && !AppModel.get_computed_pipeline().ios.appstore ? `Awaiting your permission to send your app to the App Store` : `Sending your app to the App Store` } { AppModel.get_computed_pipeline().ios.testflight && !AppModel.get_computed_pipeline().ios.appstore ? <span><Button color='black' style={{ marginTop: 10 }}><Icon name='check' /> Send my app to the App Store</Button> <Label>more info</Label></span> : null }</Label> */}
                </div>
              : null
            }
        </Modal.Description>
        <Modal.Description style={{ maxWidth: '45%' }}>
        {
              AppModel.get_computed_pipeline() ?
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 10 }}>
                      Android Status
                      { !AppModel.get_computed_pipeline().android.queue ? <Button onClick={() => USER.startBuild('android')} size='small' color='black'>Start Android Build</Button> : <Button onClick={() => USER.stopBuild('android')} size='small' color='green'>Submit new app release</Button> }
                    </Header>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      <Icon name='fly' style={{ marginBottom: 40, fontSize: 48 }} />
                      You have not yet submitted your app to the Google Play Store. If you would like to do so now, you can hit the "Submit new app release" button above.
                    </div>

                    {/* <Label color={ AppModel.get_computed_pipeline().android.queue && AppModel.get_computed_pipeline().android.building ? 'green' : AppModel.get_computed_pipeline().android.queue ? 'orange' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='clock' /> Waiting in the queue</Label>
                    <Label color={ AppModel.get_computed_pipeline().android.building && AppModel.get_computed_pipeline().android.testing ? 'green' : AppModel.get_computed_pipeline().android.building ? 'orange' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='play' /> Building your app</Label>
                    <Label color={ AppModel.get_computed_pipeline().android.testing && AppModel.get_computed_pipeline().android.testflight ? 'green' : AppModel.get_computed_pipeline().android.testing ? 'orange' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='eye' /> Testing your app</Label>
                    <Label color={ AppModel.get_computed_pipeline().android.testing && !AppModel.get_computed_pipeline().android.testflight ? `orange` : AppModel.get_computed_pipeline().android.testflight ? 'green' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='send' /> { `Sending your app to TestFlight` } { AppModel.get_computed_pipeline().android.testing && !AppModel.get_computed_pipeline().android.testflight ? <span><Button color='black' style={{ marginTop: 10 }}><Icon name='check' /> Send my app to TestFlight</Button> <Label>more info</Label></span> : null }</Label>
                    <Label color={ AppModel.get_computed_pipeline().android.testflight && !AppModel.get_computed_pipeline().android.appstore ? `orange` : AppModel.get_computed_pipeline().android.appstore ? 'green' : 'lightgrey' } style={{ marginBottom: 10 }}><Icon name='send' /> { AppModel.get_computed_pipeline().android.testflight && !AppModel.get_computed_pipeline().android.appstore ? `Awaiting your permission to send your app to the App Store` : `Sending your app to the App Store` } { AppModel.get_computed_pipeline().android.testflight && !AppModel.get_computed_pipeline().android.appstore ? <span><Button color='black' style={{ marginTop: 10 }}><Icon name='check' /> Send my app to the App Store</Button> <Label>more info</Label></span> : null }</Label> */}
                </div>
              : null
            }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
})