import { useLayoutEffect, useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'

import prepareStyles from '../styles/renderer'

export default function CustomComponent(props) {
    const [style, setStyle] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(props.style)
            setStyle(newStyles);
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.style ]);

    useEffect(() => {
        setStyle(prepareStyles(props.style))
    }, []);

    return <view style={ style }>
        <Button
            title="Apple Sign-In"
            onPress={() => console.log('Signed in with Apple!') }
        />
    </view>
}