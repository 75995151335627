import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import PaymentMethodModal from './paymentMethod';

import AppModel from '../model/app'
import USER from '../model/user'

const costPerCredit = 1

export default observer(function BuyCreditsModal(props) {
    const [open, setOpen] = React.useState(false)
    const [quantity, setQuantity] = React.useState(false)
    const [orderStart, setOrderStart] = React.useState(false)

    useEffect(() => {
        setQuantity(10)
    }, [])

    return (<Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={ props.canNotClose ? true : open }
        trigger={<Button color='green'>Buy more credits</Button>}
        >
        <Modal.Header>
            Buy Credits
        </Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Header className='upgrade-header'>
                    Limited time offer!
                </Header>
                {/* <p>You are currently on the <strong>Personal Plan</strong> ($20 per month).</p> */}

                {
                    orderStart && orderStart < JSON.parse(USER.purchase_history)[JSON.parse(USER.purchase_history).length - 1].date ?
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <strong style={{ fontSize: 24, fontWeight: 'bold' }}>Purchase Successful!</strong>
                            You now have { USER.credits } credits.
                        </div>
                    :
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            You currently have { USER.credits } credits.

                            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
                                <Input type="number" name="quantity" onChange={(ev, value) => setQuantity(parseFloat(value.value))} defaultValue={quantity} style={{ marginBottom: 10, width: 100 }} />
                                Total amount: ${ quantity * costPerCredit }
                                <Button style={{ marginTop: 20 }} color='green' onClick={() => { setOrderStart(new Date().getTime()); USER.buyCredits(quantity)} }>Buy now and save</Button>
                            </Form>
                        </div>
                }
                
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            {
            !props.canNotClose ? 
                <Button color='white' onClick={() => setOpen(false)}>
                Close
                </Button>
            : null
            }
        </Modal.Actions>
    </Modal>)
})