import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import PaymentMethodModal from './paymentMethod';

import AppModel from '../model/app'
import USER from '../model/user'
import InvoicesModal from './invoices';

function UpgradeSuccessModal(props) {
  const [open, setOpen] = React.useState(true)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => { setOpen(true) }}
      open={ open }
      trigger={<Button>Upgrade</Button>}
    >
      <Modal.Header>
        {/* <Button style={{ position: 'absolute', top: 12, right: 20 }} color='green' onClick={() => USER.logout().then(() => setOpen(false))}>Log out</Button> */}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
            <Header className='upgrade-header'>
              Upgrade successful! ✌️
            </Header>

            <div style={{ paddingTop: 20, textAlign: 'center' }}>
              <p>You are now on the <strong>{ USER.account_type === 1 ? `Personal Plan` : USER.account_type === 2 ? `Business Plan` : USER.account_type === 3 ? `Business Pro Plan` : `` }</strong>.</p>
            </div>

            <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
              <Button color='green' onClick={() => { setOpen(false) }}>Let's get going!</Button>
              <InvoicesModal />
            </div>
            
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {/* <Button color='white' onClick={() => setOpen(false)}>
          Close
        </Button> */}
      </Modal.Actions>
    </Modal>
  )
}

export default UpgradeSuccessModal