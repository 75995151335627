import { observer } from 'mobx-react'
import { Button, Icon } from 'semantic-ui-react'
import styles from '../../styles/Controls.module.css'

import MODEL from '../../model/app'

export default observer(function Controls(props) {
    return <div className={`${styles.controls} ${MODEL.in_preview_mode ? styles.preview_mode : ``}`}>
        { props.children }
        <div className={styles.controller}>
            <div className={styles.controller_buttons}>
                <Button onClick={() => props.openEditMode()} className={styles.controller_button}><Icon name='arrow left' className={styles.controller_button_icon} /></Button>
            </div>
            {/* <div className={styles.controller_buttons}>
                <Button onClick={() => props.moveComponent('left')} className={styles.controller_button}><Icon name='arrow left' className={styles.controller_button_icon} /></Button>
                <Button onClick={() => props.moveComponent('up')} className={styles.controller_button}><Icon name='arrow up' className={styles.controller_button_icon} /></Button>
                <Button onClick={() => props.moveComponent('down')} className={styles.controller_button}><Icon name='arrow down' className={styles.controller_button_icon} /></Button>
                <Button onClick={() => props.moveComponent('right')} className={styles.controller_button}><Icon name='arrow right' className={styles.controller_button_icon} /></Button>
            </div>
            <div className={styles.controller_buttons}>
                <Button onClick={() => props.openEditMode()} className={styles.controller_button}><Icon name='setting' className={styles.controller_button_icon} /></Button>
                <Button onClick={() => props.duplicateComponent()} className={styles.controller_button}><Icon name='copy' className={styles.controller_button_icon} /></Button>
            </div>
            <div className={styles.controller_buttons}>
                <Button onClick={() => props.deleteComponent()} className={[styles.controller_button, styles.controller_button_warning]}><Icon name='delete' className={styles.controller_button_icon} /></Button>
            </div> */}
        </div>
    </div>
})