import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import UpgradeModal from './upgrade'

import AppModel from '../model/app'
import USER from '../model/user'

function InvoicesModal() {
  const [open, setOpen] = React.useState(false)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Need a receipt?</Button>}
    >
      <Modal.Header>
        Purchase history
        {/* <Button style={{ position: 'absolute', top: 12, right: 20 }} color='green' onClick={() => USER.logout().then(() => setOpen(false))}>Log out</Button> */}
      </Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
            <Header>My Receipts</Header>
            
            <div>
                { USER.getPurchaseHistory() }
            </div>

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='white' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default InvoicesModal