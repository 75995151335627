import React from 'react'
import { observer } from 'mobx-react'
import { Button, Header, Image, Modal, Form, Input, Select, Accordion, Label } from 'semantic-ui-react'

import AppModel from '../model/app'
import USER from '../model/user'
import MediaGalleryModal from './mediaGallery'
import MediaThumbnail from '../renderer/media/thumbnail'

export default observer(function PushNotificationsModal() {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState(false)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Push Notifications</Button>}
    >
      <Modal.Header>
        Sending a Push Notification
      </Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>

            <Label style={{ display: 'flex', alignItems: 'center', margin: '0 0 20px' }}>
                <strong style={{ marginRight: 10 }}>Title</strong>
                <Input type='text' name='title' defaultValue='' onChange={(ev, value) => { setMessage({ ...message, headline: value.value}) }} />
            </Label>

            <Label style={{ display: 'flex', alignItems: 'center', margin: '0 0 20px' }}>
                <strong style={{ marginRight: 10 }}>Message</strong>
                <Input type='text' name='body' defaultValue='' onChange={(ev, value) => { setMessage({ ...message, message: value.value}) }} />
            </Label>

            {/* <Label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '0 0 20px', padding: '15px 10px' }}>
                <strong style={{ marginRight: 10 }}>Image</strong>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
                    { AppModel.push_notification && JSON.parse(AppModel.push_notification) && JSON.parse(AppModel.push_notification).image ? <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'center', alignItems: 'center', width: 100, height: 100, backgroundColor: 'white' }}><MediaThumbnail id={ JSON.parse(AppModel.push_notification).image } width={'100%'} height={'auto'} /></div> : null }
                    <MediaGalleryModal componentInEditMode={true} attribute={`push_notification_image`} />
                    { AppModel.push_notification && JSON.parse(AppModel.push_notification) && JSON.parse(AppModel.push_notification).image ? <Button color='red' style={{ marginTop: 10 }} onClick={() => AppModel.removePushNotificationImage()}>Remove Image</Button> : null }
                </div>
            </Label> */}

            <Label style={{ display: 'flex', alignItems: 'center', margin: '0 0 20px' }}>
                <strong style={{ marginRight: 10 }}>Target</strong>
                <Select placeholder='Target destination' options={
                  AppModel.screens.map((screen) => {
                    return { key: screen.original_id, value: screen.original_id, text: screen.original_id }
                  })
                } onChange={(ev, value) => { setMessage({ ...message, target: value.value}) }} />
            </Label>

            <Button color='green' onClick={() => { AppModel.sendPush(message).then((success) => { if (success) { setOpen(false) } else { alert('error sending push!') } }) }}>
                Send Push Notification
            </Button>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='white' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
})