import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDRZd-MozjEzYEgD_Hwdmbz2mhZ16mUNvA",
  authDomain: "buildspace-core.firebaseapp.com",
  projectId: "buildspace-core",
  storageBucket: "buildspace-core.buildspace.app",
  messagingSenderId: "1018428393585",
  appId: "1:1018428393585:web:44c13b9b9bdd04f271f3d0",
  measurementId: "G-2MT4DLL7HN"
};

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const firestore = getFirestore(app)

export default app;