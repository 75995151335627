import React from 'react'

import styles from '../styles/Hint.module.css'

function Hint(props) {
    
    switch (props.type) {
        case 'choose_screen': 
            return (<p className={styles.hint}>Choose an existing screen to modify.<br/>Or, add a new screen.</p>)
        break;
        default: 
            return null
        break;
    }
}

export default Hint