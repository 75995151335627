import { useState } from 'react'

import USER from '../../model/user'

import CDN from '../../service/cdn'

export default function MediaThumbnail(props) {
    const [source, setSource] = useState()

    console.log('thumb props', props)

    return <div>
        {
            props.source && props.source.includes('.m3u8') ? <video src={`${props.source}`} autoPlay={false} muted loop={true} playsinline controls width="100%" height="100%"><source src={`${props.source}`} type="application/x-mpegURL" /></video> : null
        }
        {
            props.source && (props.source.includes('.jpeg') || props.source.includes('.png') || props.source.includes('.gif') || props.source.includes('.bmp') || props.source.includes('.svg')) ? <img src={ `${props.source}` } style={{ width: '100%', height: 'auto' }} /> : null
        }
        {
            props.id && (props.id.includes('.jpeg') || props.id.includes('.png') || props.id.includes('.gif') || props.id.includes('.bmp') || props.id.includes('.svg')) ? <img src={ `${CDN.base}/${USER.email}/${props.id}` } style={{ width: '100%', height: 'auto' }} /> : null
        }
    </div>
}