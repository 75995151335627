import { observer } from 'mobx-react'
import { useLayoutEffect, useState, useEffect } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel'

import prepareStyles from '../styles/renderer'
import Controls from '../helper/controls'

import { renderComponent } from '../renderer'

export default observer(function CustomComponent(props) {
    const [hover, setHover] = useState()
    const [value, setValue] = useState(0)
    const onChange = value => {
        setValue(value);
    }

    const [style, setStyle] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(props.style)
            setStyle(newStyles);
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.style ]);

    useEffect(() => {
        setStyle(prepareStyles(props.style))
    }, []);

    return <div style={ style }>
        {
            typeof window !== 'undefined' ?
                <Carousel value={value} onChange={onChange} style={{ position: 'relative' }}>
                    { props.children ? props.children.map((component) => {
                        return <div style={{ position: 'relative', width: '100%', height: '100%' }}>{ renderComponent(component, props.originalProps) }</div>
                    }) : null }
                </Carousel>
            : null
        }
        {
            <Dots
                value={value}
                onChange={onChange}
                thumbnails={
                    props.children.map((child) => {
                        return true
                    })
                }
            />
        }
    </div>
})