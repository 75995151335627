import { useLayoutEffect, useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'

import prepareStyles from '../styles/renderer'
import styles from '../../styles/Controls.module.css'

import MODEL from '../../model/app'
import USER from '../../model/user'

import Controls from '../helper/controls'

import CDN from '../../service/cdn'

export default function CustomComponent(props) {
    const [ hover, setHover ] = useState()
    const [style, setStyle] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(props.style)
            setStyle(newStyles);
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.style ]);

    useEffect(() => {
        setStyle(prepareStyles(props.style))
    }, []);
 
    return <a href="#" data-highlight data-controls style={ style } className={[ hover && !props.hideControls ? styles.highlight : `` ]} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={(ev) => { props.handleClick(ev, props.attributes.destination_type, props.attributes.destination) }}>
        <video width="100%" height="100%" src={`${CDN.base}/${USER.email}/${props.attributes.source}`} poster={`${CDN.base}/${USER.email}/${props.attributes.poster}`} loop={ props.attributes.loop ? true : false } playsinline controls={ props.attributes.controls ? true : false } autoPlay={ props.attributes.autoplay ? true : false } muted={ props.attributes.muted ? true : false }> 
            <source src={`${CDN.base}/${USER.email}/${props.attributes.source}`} type="video/mp4" />
        </video>
    </a>
}