import React from 'react'
import { Button, Header, Image, Modal, Label, Input, Select } from 'semantic-ui-react'

function UserSettingsModal(props) {
  const [open, setOpen] = React.useState(typeof props.open !== 'undefined' ? props.open : false)

  return (
    <Modal
      onClose={() => { setOpen(false); props.onClose ? props.onClose() : null; }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>User Settings</Button>}
    >
      <Modal.Header>User Settings</Modal.Header>
      <Modal.Content image scrolling style={{ minHeight: '50vh' }}>
        <Modal.Description>
          <Header>Paid Subscribers</Header>
          <p>
            How much would you like to charge users to become paid subscribers?
            <br/>
            <em><strong>Note:</strong> This is for new subscribers; existing subscriptions are unaffected.</em>
          </p>
          <Label style={{ marginBottom: 20, width: '100%' }}>
            <span style={{ marginRight: 10 }}>Amount</span>
            <Input type='text' defaultValue='0.00' />
            <Select placeholder='Currency' options={[
                  { key: 'usd', value: 'usd', text: 'US Dollar ($)' },
                  { key: 'eur', value: 'eur', text: 'Euro (€)' },
                  { key: 'gbp', value: 'gbp', text: 'British Pound (£)' },
              ]} onChange={ (e, data) => {  } } />
          </Label>

          <p>How often should the subscription renew?</p>
          <Label style={{ marginBottom: 20, width: '100%' }}>
            <span style={{ marginRight: 10 }}>How often</span>
            <Select placeholder='How often' options={[
                  { key: 'monthly', value: 'monthly', text: 'Monthly (every month)' },
                  { key: 'half-yearly', value: 'half-yearly', text: 'Half-Yearly (every 6 months)' },
                  { key: 'yearly', value: 'yearly', text: 'Yearly (every year)' },
              ]} onChange={ (e, data) => {  } } />
          </Label>

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='white' onClick={() => { setOpen(false); props.onClose ? props.onClose() : null; }}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UserSettingsModal