import { useLayoutEffect, useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'

import prepareStyles from '../styles/renderer'
import styles from '../../styles/Controls.module.css'

import Controls from '../helper/controls'

export default function CustomComponent(props) {
    const [hover, setHover] = useState()
    const [style, setStyle] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(props.style)
            setStyle(newStyles);
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.style ]);

    useEffect(() => {
        setStyle(prepareStyles(props.style))
    }, []);

    return <a href="#" data-highlight data-controls style={ style } className={[ hover && !props.hideControls ? styles.highlight : `` ]} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={(ev) => { props.handleClick(ev, props.attributes.destination_type, props.attributes.destination) }}>{ props.attributes.text }</a>
}

