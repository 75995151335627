import { useLayoutEffect, useState, useEffect } from 'react';
import { observer } from 'mobx-react'

import MODEL from '../../model/app'

import prepareStyles from '../styles/renderer'

export default observer(function renderMenuTab(props) {
    const [style, setStyle] = useState()
    const [position, setPosition] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(MODEL.menu.computed_styles())
            setStyle(newStyles);
            let newPosition = prepareStyles(MODEL.menu.computed_tab_position())
            setPosition(newPosition);
            console.log('NEW POSITION ON RESIZE', newPosition)
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.style ]);

    useEffect(() => {
        setStyle(prepareStyles(MODEL.menu.computed_styles()))
        setPosition(prepareStyles(MODEL.menu.computed_tab_position()))
    }, []);
    
    return <div style={ position }><div onClick={ (ev) => { ev.stopPropagation(); MODEL.openMenuEditMode() } } style={ style }><img src={ MODEL.getMediaItemLocationByID(MODEL.menu.computed_tab_icon()) } /></div></div>
})