import { useLayoutEffect, useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react'

import MODEL from '../../model/app';

import prepareStyles from '../styles/renderer'

import Controls from '../helper/controls'
import styles from '../../styles/Controls.module.css'

export default function CustomComponent(props) {
    const [source, setSource] = useState()
    const [hover, setHover] = useState()

    const [style, setStyle] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(props.attributes.styles)
            setStyle(newStyles);
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.attributes.styles ]);

    useEffect(() => {
        setStyle(prepareStyles(props.attributes.styles))
    }, []);

    return style && style.width && style.width !== 'NaNpx' && style.height && style.height !== 'NaNpx' ? <a href="#" data-highlight className={[ hover && !props.hideControls ? styles.highlightWithoutRelativity : `` ]} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={(ev) => { props.handleClick(ev, props.attributes.destination_type, props.attributes.destination) }}><img src={ props.attributes.source } objectFit='contain' layout={ props.attributes.layout ? props.attributes.layout : `intrinsic` } style={ style } width={ style.width } height={ style.height } /></a> : null
}