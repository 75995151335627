/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree'

import Component from './component'

const ComponentAttributes = types
  .model('ComponentAttributes', {
    id: types.string,
    text: types.optional(types.string, ''),
    layout: types.optional(types.string, ''),
    direction: types.optional(types.string, 'row'),
    source: types.optional(types.string, ''),
    poster: types.optional(types.string, ''),
    autoplay: types.optional(types.boolean, true),
    muted: types.optional(types.boolean, true),
    loop: types.optional(types.boolean, true),
    controls: types.optional(types.boolean, false),
    theme: types.optional(types.string, ''),
    styles: types.maybeNull(types.string),
    destination: types.maybeNull(types.string, ''),
    destination_type: types.maybeNull(types.string, ''),
  })
  .actions(self => ({
    updateStyles: flow(function* (styles) {
      self.styles = JSON.stringify(styles)
    }),
    updateSingleStyle: flow(function* (key, value) {
      let styles = JSON.parse(self.styles)

      styles[key] = value

      self.styles = JSON.stringify(styles)
    }),
    updateSingleAttribute: flow(function* (key, value) {
      self[key] = value
    }),
    setSource: flow(function* (source) {
      self.source = source
    }),
    setPoster: flow(function* (poster) {
      self.poster = poster
    })
  }))
  .views(self => ({
    computed_styles() {
      let styles = JSON.parse(self.styles)

      // All items must be flex
      styles['display'] = 'flex';

      if (!self.direction || self.direction === '' || self.direction === 'row') {

        styles['flex-direction'] = 'row';

        if (!self.layout || self.layout === '' || self.layout === 'middle') {
          styles['justify-content'] = 'center';
          styles['align-items'] = 'center';
        } else if (self.layout === 'middle-left') {
          styles['justify-content'] = 'flex-start';
          styles['align-items'] = 'center';
        } else if (self.layout === 'middle-right') {
          styles['justify-content'] = 'flex-end';
          styles['align-items'] = 'center';
        } else if (self.layout === 'top-middle') {
          styles['justify-content'] = 'center';
          styles['align-items'] = 'flex-start';
        } else if (self.layout === 'top-left') {
          styles['justify-content'] = 'flex-start';
          styles['align-items'] = 'flex-start';
        } else if (self.layout === 'top-right') {
          styles['justify-content'] = 'flex-end';
          styles['align-items'] = 'flex-start';
        } else if (self.layout === 'bottom-left') {
          styles['justify-content'] = 'flex-start';
          styles['align-items'] = 'flex-end';
        } else if (self.layout === 'bottom-middle') {
          styles['justify-content'] = 'center';
          styles['align-items'] = 'flex-end';
        } else if (self.layout === 'bottom-right') {
          styles['justify-content'] = 'flex-end';
          styles['align-items'] = 'flex-end';
        }

      } else {

        styles['flex-direction'] = 'column';

        if (!self.layout || self.layout === '' || self.layout === 'middle') {
          styles['justify-content'] = 'center';
          styles['align-items'] = 'center';
        } else if (self.layout === 'middle-left') {
          styles['justify-content'] = 'center';
          styles['align-items'] = 'flex-start';
        } else if (self.layout === 'middle-right') {
          styles['justify-content'] = 'center';
          styles['align-items'] = 'flex-end';
        } else if (self.layout === 'top-middle') {
          styles['justify-content'] = 'flex-start';
          styles['align-items'] = 'center';
        } else if (self.layout === 'top-left') {
          styles['justify-content'] = 'flex-start';
          styles['align-items'] = 'flex-start';
        } else if (self.layout === 'top-right') {
          styles['justify-content'] = 'flex-start';
          styles['align-items'] = 'flex-end';
        } else if (self.layout === 'bottom-left') {
          styles['justify-content'] = 'flex-end';
          styles['align-items'] = 'flex-start';
        } else if (self.layout === 'bottom-middle') {
          styles['justify-content'] = 'flex-end';
          styles['align-items'] = 'center';
        } else if (self.layout === 'bottom-right') {
          styles['justify-content'] = 'flex-end';
          styles['align-items'] = 'flex-end';
        }
      }

      return styles
    },
    computed_attributes() {
      return {
        text: self.text,
        layout: self.layout,
        direction: self.direction,
        autoplay: self.autoplay,
        muted: self.muted,
        loop: self.loop,
        controls: self.controls,
        source: self.source,
        poster: self.poster,
        theme: self.theme,
        styles: JSON.parse(self.styles),
        destination: self.destination,
        destination_type: self.destination_type,
      }
    }
  }))

export default ComponentAttributes;
