import { useLayoutEffect, useState, useEffect } from 'react';
import { observer } from 'mobx-react'

import MODEL from '../../model/app'

import prepareStyles from '../styles/renderer'

export default observer(function renderMenuCloseButton(props) {
    const [style, setStyle] = useState()

    useEffect(() => {
        function updateStyles() {
            let newStyles = prepareStyles(MODEL.menu.computed_close_styles())
            setStyle(newStyles);
        }
        window.addEventListener('resize', updateStyles);
        updateStyles();
        return () => window.removeEventListener('resize', updateStyles);
    }, [ props.style ]);

    useEffect(() => {
        setStyle(prepareStyles(MODEL.menu.computed_close_styles()))
    }, []);

    return <div style={ MODEL.menu.computed_tab_close_position() }><div onClick={ (ev) => { ev.stopPropagation(); MODEL.openMenuEditMode() } } style={ style }><img src={ MODEL.getMediaItemLocationByID(MODEL.menu.computed_tab_close_icon()) } /></div></div>
})