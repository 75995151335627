import React from 'react'
import { Button, Header, Image, Modal, Form, Input, Select, Icon } from 'semantic-ui-react'
import optionsLogin from '../options/login'

import MODEL from '../model/app'

function AddScreenModal() {
  const [open, setOpen] = React.useState(false)

  let screen = {
    id: '',
    type: 'screen',
    should_bounce: 'false',
    access_rule: 'false',
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button style={{ height: '2.71428571em', borderRadius: '0 4px 4px 0' }}><Icon name='plus' style={{ margin: 0 }} /></Button>}
    >
      <Modal.Header>Add a new screen</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
            <Header>Screen name</Header>
            <p>This is how you identify this screen from other screens.</p>
            <Form.Field>
                <Input onChange={ (e, data) => { screen.id = data.value } } defaultValue={ `` }  />
                <em>must be unique</em>
            </Form.Field>
            <Header>Type of screen</Header>
            <p>Are you looking to add a new screen or a modal?</p>
            <Form.Field>
                <Select placeholder='Type of screen' options={[
                    { key: 'screen', value: 'screen', text: 'Screen' },
                    { key: 'modal', value: 'modal', text: 'Modal' },
                ]} onChange={ (e, data) => { screen.type = data.value } } defaultValue={ screen.type } />
            </Form.Field>
            <Header>Content bouncing</Header>
            <p>Should the content bounce on scrolling?</p>
            <Form.Field>
                <Select placeholder='Content should bounce?' options={[
                    { key: 'true', value: 'yes', text: 'Yes' },
                    { key: 'false', value: 'no', text: 'No' },
                ]} onChange={ (e, data) => { screen.should_bounce = data.value } } defaultValue={ screen.should_bounce } />
            </Form.Field>
            <Header>Access rules</Header>
            <p>Should this screen be accessible for all users?</p>
            <Form.Field>
                <Select placeholder='Accessible for all users?' options={[
                    { key: 'true', value: 'yes', text: 'Yes - all app users' },
                    { key: 'false', value: 'no', text: 'No - only logged in users' },
                ]} onChange={ (e, data) => { screen.access_rule = data.value } } defaultValue={ screen.access_rule } />
            </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Add Screen"
          labelPosition='right'
          icon='checkmark'
          onClick={() => { MODEL.addScreen(screen); setOpen(false); }}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AddScreenModal