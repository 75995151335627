/*eslint require-yield: "off"*/
import { types, flow, getSnapshot } from 'mobx-state-tree'

const PipelineStatus = types
  .model('PipelineStatus', {
    ios: types.maybeNull(types.string),
    android: types.maybeNull(types.string)
  })
  .actions(self => ({
 
  }))
  .views(self => ({
    get_computed_pipeline_status() {
      return {
        ios: `${self.ios}`,
        android: `${self.android}`
      }
    }
  }))

export default PipelineStatus;
