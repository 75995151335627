import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import 'react-dropzone-uploader/dist/styles.css'
import styles from './styles/Home.module.css'
import {
  Grid,
  Header,
  Item,
  Rail,
  Ref,
  Segment,
  Sticky,
  Button,
  Checkbox,
  Form,
  Input,
  Placeholder,
  Select,
  Label,
  Menu,
  Tab,
  Modal,
  Dropdown,
  Icon
} from 'semantic-ui-react'
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import MODEL from './model/app'
import USER from './model/user'

import API from './service/api'

import AddScreenModal from './modals/addScreen'
import UserSettingsModal from './modals/userSettings'
import MenuModal from './modals/menu'
import AccountModal from './modals/account'
import AddComponentModal from './modals/addComponent'
import AskVersionModal from './modals/askVersion'
import PipelineModal from './modals/pipeline'
import MediaGalleryModal from './modals/mediaGallery'
import UsersModal from './modals/users'
import PushNotificationsModal from './modals/notifications'
import MediaUpload from './modals/mediaUpload'
import AssetStoreModal from './modals/assetStore'
import UpgradeModal from './modals/upgrade'
import UpgradeSuccessModal from './modals/upgradeSuccess'
import BuyCreditsSuccessModal from './modals/buyCreditsSuccess'

import Hint from './hints/hint'

import Renderer from './renderer/renderer'
import RenderMenuTab from './renderer/menu/menuTab'
import RenderMenuCloseTab from './renderer/menu/MenuCloseTab'

USER.init()

function SafeHydrate({ children }) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  )
}

export default observer(function Home() {
  const [openModal, setOpenModal] = useState(null)

  useEffect(() => {
    USER.getProject(false)
  }, []);

  return <SafeHydrate>

      {
        USER.isLoggedIn() && !USER.isProjectLoading() ?
          <div className={`${styles.container} bg-gradient-to-r from-gray-700 via-gray-900 to-black`}>
            <header className={`${styles.header} ${MODEL.in_preview_mode ? styles.header_in_preview_mode : ``}`}>
              <svg className={styles.logo} width="70px" height="98px" xmlns="http://www.w3.org/2000/svg">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" font-family="Quad-Light" font-size="100" font-weight="300" letter-spacing="1.38888889">
                      <text id="B" fill="#FFFFFF">
                          <tspan x="0.745555556" y="98">B</tspan>
                      </text>
                  </g>
              </svg>
              <div>
                <Select style={{ marginRight: 5, width: 80, maxWidth: 80, minWidth: 80 }} placeholder='Select scale' options={[
                    { key: '175%', value: 1.75, text: '175%' },
                    { key: '150%', value: 1.50, text: '150%' },
                    { key: '125%', value: 1.25, text: '125%' },
                    { key: '120%', value: 1.20, text: '120%' },
                    { key: '115%', value: 1.15, text: '115%' },
                    { key: '110%', value: 1.10, text: '110%' },
                    { key: '105%', value: 1.05, text: '105%' },
                    { key: '100%', value: 1.0, text: '100%' },
                    { key: '90%', value: 0.90, text: '90%' },
                    { key: '80%', value: 0.80, text: '80%' },
                ]} onChange={ (e, data) => { MODEL.setDeviceScale(data.value) } } defaultValue={ MODEL.device_scale } />
                <Select style={{ borderRadius: '4px 0 0 4px' }} placeholder='Select a screen to manage' options={ MODEL.getScreenList() } defaultValue={ `` } onChange={(ev, data) => MODEL.setCurrentScreen(data.value)} />
                <AddScreenModal />
              </div>
              <div>
                <PipelineModal />

                <Button.Group color='teal'>
                  <Button key={`save_publish`} onClick={() => USER.saveProjectPublish()}>Save</Button>
                  <Dropdown
                    className='button icon'
                    floating
                    options={[
                      { style: { color: '#940404', borderRadis: 4, overflow: 'hidden' }, key: 'revert_changes', icon: 'delete', text: 'Revert all unsaved changes', value: 'Revert all unsaved changes' },
                    ]}
                    trigger={<></>}
                  />
                </Button.Group>

                {/* <Button className={`${MODEL.in_preview_mode ? styles.button_in_preview_mode : ``}`} onClick={(ev) => { ev.preventDefault(); MODEL.setPreviewMode(MODEL.in_preview_mode ? false : true) }}>{ MODEL.in_preview_mode ? `Exit preview mode` : `Start preview mode` }</Button> */}
              </div>
              <div>
                {/* <MediaGalleryModal /> */}
                {/* <UserSettingsModal /> */}
                {/* <UsersModal /> */}

                <PushNotificationsModal />

                <Button.Group style={{ marginRight: '0.25rem' }}>
                  <MediaGalleryModal />
                  <Dropdown
                    className='button icon'
                    floating
                    options={[
                      { key: 'upload_new_media', text: 'Upload new asset', onClick: () => { setOpenModal(<MediaUpload open={true} onClose={() => { setOpenModal(null) }} />) } },
                    ]}
                    trigger={<></>}
                  />
                </Button.Group>

                <AssetStoreModal />

                <Button.Group style={{ marginRight: '0.25rem' }}>
                  <UsersModal />
                  <Dropdown
                    className='button icon'
                    floating
                    options={[
                      { key: 'users_settings', text: 'User Settings', onClick: () => { setOpenModal(<UserSettingsModal open={true} onClose={() => { setOpenModal(null) }} />) } },
                    ]}
                    trigger={<></>}
                  />
                </Button.Group>

                <Button.Group color='teal'>
                  <AccountModal />
                  <Dropdown
                    className='button icon'
                    floating
                    options={[
                      { key: 'logout', text: 'Logout', onClick: () => { USER.logout() } },
                    ]}
                    trigger={<></>}
                  />
                </Button.Group>
              </div>
            </header>
            
            <main className={`${styles.main}`}>

              {
                MODEL.creation_status === '0' && MODEL.show_creation_status_prompt ? <div style={{ position: 'fixed', top: 90, left: 10, padding: 10, width: '30%', backgroundColor: '#1D1E1F', border: '1px solid white', borderRadius: 5, zIndex: 999 }}>Your account is currently being prepared. This can take up to an hour. <br /><br/>Don't worry, you can start building your app now and we'll let you know when everything is ready.<br/><br/><a href="#" onClick={(ev) => { ev.preventDefault(); MODEL.setShowCreationStatusPrompt(false) }}>[ Close this message ]</a></div> : null
              }
              {
                MODEL.creation_status === '1' && MODEL.show_creation_status_prompt ? <div style={{ position: 'fixed', top: 90, left: 10, padding: 10, width: '30%', backgroundColor: '#1D1E1F', border: '1px solid white', borderRadius: 5, zIndex: 999 }}>Your account has been successfully prepared! Enjoy creating your project!<br/><br/><a href="#" onClick={(ev) => { ev.preventDefault(); MODEL.setShowCreationStatusPrompt(false) }}>[ Close this message ]</a></div> : null
              }

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 75px)' }} onClick={() => { MODEL.exitMenuEditMode(); MODEL.current_screen.exitEditMode() }}>
                <div style={{ position: 'relative', padding: '0 50px 0 0', height: 'calc(100vh - 120px)' }}>
                  <div className={styles.iphoneCase} style={{ transform: `scale(${MODEL.device_scale})`, transformOrigin: `top center` }}>
                    <div data-device className={styles.iphone} style={{ width: 330, height: 660 }}>
                      { MODEL.current_screen && !MODEL.current_screen.is_menu && MODEL.current_screen.type !== 'modal' ? <RenderMenuTab /> : MODEL.current_screen ? <RenderMenuCloseTab /> : null }
                      <div className={`${styles.iphoneInner} ${MODEL.device_is_loading ? styles.iphoneInnerLoading : ''}`}>
                        { MODEL.current_screen ? MODEL.renderCurrentScreen() : null }
                      </div>
                    </div>
                    <div className={styles.volumeButton}>
                        
                    </div>
                    <div className={styles.powerButton}>
                        
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={styles.deviceOuter} onClick={() => { MODEL.exitMenuEditMode(); MODEL.current_screen.exitEditMode() }}>
                <div data-device className={`${styles.device}`} style={{ aspectRatio: '9/19.5' }}>
                  <div className={styles.deviceTab}>
                    <img src={'/device-camera.svg'} className={styles.deviceTabInner} />
                  </div>
                  { MODEL.show_status_bar ? <div className={styles.statusBar}><span>Telekom</span><span style={{ display: 'flex', width: '20%', justifyContent: 'space-around' }}><Icon name='wifi' /><Icon name='battery half' /></span></div> : null }
                  <div className={styles.deviceInner}>
                    { MODEL.current_screen ? MODEL.renderCurrentScreen() : null }
                  </div>
                </div>
              </div> */}

              { MODEL.current_screen ? MODEL.current_screen.renderEditComponentWindow() : null }
              
              {/* <Button key={`source_code_copy`} className={styles.copySourceCode} onClick={() => navigator.clipboard.writeText(MODEL.renderOutput())}>Copy Source Code</Button> */}
              <Button key={`trigger_local_build`} className={styles.triggerLocalBuild} onClick={() => triggerLocalBuild()}>Run in Simulator</Button>
              {/* <Button key={`save_publish`} className={styles.copySourceCode} onClick={() => USER.saveProjectPublish()}>Publish</Button> */}

              { MODEL.current_screen && !MODEL.in_preview_mode ? <AddComponentModal /> : !MODEL.in_preview_mode ? <Hint type='choose_screen' /> : null }

              { USER.isPublishSaving() ? <strong className={styles.savingDraftNotice}>Saving...</strong> : null }

            </main>
          </div>
        : USER.isLoggedIn() && USER.isProjectLoading() ?
          <div className={`${styles.container} bg-gradient-to-r from-gray-700 via-gray-900 to-black`}>
            <header className={`${styles.header} ${MODEL.in_preview_mode ? styles.header_in_preview_mode : ``}`}>
                <div>
                  <svg className={styles.logo} width="70px" height="98px" xmlns="http://www.w3.org/2000/svg">
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" font-family="Quad-Light" font-size="100" font-weight="300" letter-spacing="1.38888889">
                          <text id="B" fill="#FFFFFF">
                              <tspan x="0.745555556" y="98">B</tspan>
                          </text>
                      </g>
                  </svg>
                </div>
                <div>
                  {/* <AccountModal /> */}
                </div>
            </header>

            <main className={`${styles.main}`}>

              <div style={{ position: 'absolute', visibility: 'hidden' }}>
                <div data-device className={`${styles.device}`} style={{ aspectRatio: '9/19.5' }}>
                  <div className={styles.deviceInner}></div>
                </div>
              </div>
              
              <div className={`${styles.loadingContainer}`}>
                <h3 className={`${styles.loadingHeading}`}>Hello, { USER.getDisplayName() } 👋 </h3>
                <Button color='green' onClick={() => { USER.getProject(false) }}>Load my project</Button>
                {
                  USER.shouldAskVersionToUse() ? <AskVersionModal /> :  null
                }
                {/* <h4 className={`${styles.loadingSubheading}`}>We are retrieving your project. This will only take a moment. { JSON.stringify(USER.isProjectLoading()) }</h4> */}
              </div>

            </main>
          </div>
        :
        <div className={`${styles.container} bg-gradient-to-r from-gray-700 via-gray-900 to-black`}>
          <header className={`${styles.header} ${MODEL.in_preview_mode ? styles.header_in_preview_mode : ``}`}>
            <svg className={styles.logo} width="70px" height="98px" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" font-family="Quad-Light" font-size="100" font-weight="300" letter-spacing="1.38888889">
                    <text id="B" fill="#FFFFFF">
                        <tspan x="0.745555556" y="98">B</tspan>
                    </text>
                </g>
            </svg>
            <h1 className={styles.inactiveHeaderText}>Welcome to BuildSpace</h1>
          </header>
          <main className={`${styles.main}`}>
            <div className={`${styles.loginOptions}`}>
              <Button onClick={() => USER.loginWithGoogle()}>Login with Google</Button>
            </div>
          </main>
        </div>
      }

      { USER.email && (!USER.account_type || USER.account_type === 0) && typeof window !== 'undefined' && window.location.hash !== '#/success' ? <UpgradeModal canNotClose={true} /> : null }

      { typeof window !== 'undefined' && window.location.hash === '#/success' ? <UpgradeSuccessModal /> : null }
      
      { typeof window !== 'undefined' && window.location.hash === '#/buyCreditSuccess' ? <BuyCreditsSuccessModal /> : null }

      { openModal ? openModal : null }
    </SafeHydrate>
})

function triggerLocalBuild() {
  
  fetch(`${API.base}/system/build`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: MODEL.renderOutput(),
  })
}